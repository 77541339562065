import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import { useActions } from "../../../hook/useActions";
import Button from "@mui/material/Button";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import MoneyIcon from '@mui/icons-material/Money';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import Timeline from '@mui/icons-material/Timeline';
const PanelDrawer = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useTypedSelector((state) => state.user);
  const { logout } = useActions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const drawerWidth = 300;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window.document.body;
  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  const avatar = () => {
    if (user != undefined) {
      const { firstName, lastName } = user;
      return (
        <>
          <Avatar />
          <Typography sx={{ marginLeft: 2, fontWeight: 600, color: "#811B7E" }}>
            {firstName + " " + lastName}
          </Typography>
        </>
      );
    }
  };

  const drawer = (
    <div>
      <Toolbar>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {avatar()}
        </div>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:dashboard")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("enterprices");
            }}
          >
            <ListItemIcon>
              <BusinessIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:enterpricesTitle")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("incomeData");
            }}
          >
            <ListItemIcon>
              <ListAltIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:incomedataTitle")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("assetManagement");
            }}
          >
            <ListItemIcon>
              <AssessmentIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:assetManagement")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("profitManagement");
            }}
          >
            <ListItemIcon>
              <MonetizationOnIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:profitManagement")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("personnelManagement");
            }}
          >
            <ListItemIcon>
              <SettingsAccessibilityIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:personnelManagement")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("capitalManagment");
            }}
          >
            <ListItemIcon>
              <MoneyIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:capitalManagment")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("liquidityAnalysis");
            }}
          >
            <ListItemIcon>
              <CallMissedOutgoingIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:liquidityAnalysis")} />
          </ListItemButton>
        </ListItem>
        
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("bankuptcyAnalysis");
            }}
          >
            <ListItemIcon>
              <MoneyOffIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:bankuptcyAnalysis")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("graphAnalysisFVFM");
            }}
          >
            <ListItemIcon>
              <AutoGraphIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:graphAnalysisFVFM")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("graphCoefUsing");
            }}
          >
            <ListItemIcon>
              <Timeline color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:graphCoefUsing")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("panelMenu:logout")} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          borderBottom: "#D3D3D3 0.5px solid",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              marginLeft: "10px",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Button
              onClick={() => {
                handleChangeLang("uk");
              }}
              sx={{
                textTransform: "capitalize",
                textDecoration: "none",
                fontWeight: 400,
                display: "inline-block",
                fontSize: "18px",
              }}
            >
              UA
            </Button>
            <Typography fontSize={18} lineHeight={"40px"} color="primary">
              |
            </Typography>
            <Button
              onClick={() => {
                handleChangeLang("en");
              }}
              sx={{
                textTransform: "capitalize",
                textDecoration: "none",
                fontWeight: 400,
                display: "inline-block",
                fontSize: "18px",
              }}
            >
              EN
            </Button>
          </div>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ ml: 2 }}
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
export default PanelDrawer;
