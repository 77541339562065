import {
  EnterpriceActions,
  IEnterpricesState,
  EnterpricesActionsTypes,
  KvedsActionsTypes,
  CreateEnterpriceActionsTypes,
  EditEnterpriceActionsTypes,
  DeleteEnterpriceActionsTypes,
} from "./types";

const initialState: IEnterpricesState = {
  enterprices: [],
  kveds:[],
  getEnterprices: {
    loading: false,
    error: null,
    success: false,
  },
  getKveds:{
    loading: false,
    error: null,
    success: false
  },
  createEnterprice:{
    loading: false,
    error: null,
    success: false
  },
  editEnterprice:{
    loading: false,
    error: null,
    success: false
  },
  deleteEnterprice:{
    loading: false,
    error: null,
    success: false
  }
};

const enterpricesReducer = (
  state: IEnterpricesState = initialState,
  action: EnterpriceActions
) => {
  switch (action.type) {
    case EnterpricesActionsTypes.GET_ENTERPRICES: {
      return {
        ...state,
        getEnterprices: {
          loading: true,
          error: null,
        },
      };
    }
    case EnterpricesActionsTypes.GET_ENTERPRICES_SUCCESS: {
      const { loading, enterprices } = action;
      return {
        ...state,
        enterprices,
        getEnterprices: {
          loading,
          success: true,
        },
      };
    }

    case EnterpricesActionsTypes.GET_ENTERPRICES_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getEnterprices: {
          error,
          loading,
        },
      };
    }
    case EnterpricesActionsTypes.SET_DEFAULT: {
      return {
        ...state,
        getEnterprices: {
          error: null,
          loading: false,
          success: false,
        },
      };
    }
    
    case KvedsActionsTypes.GET_KVEDS: {
      return {
        ...state,
        getKveds: {
          loading: true,
          error: null,
        },
      };
    }
    case KvedsActionsTypes.GET_KVEDS_SUCCESS: {
      const { loading, kveds } = action;
      return {
        ...state,
        kveds,
        getKveds: {
          loading,
          success: true,
        },
      };
    }

    case KvedsActionsTypes.GET_KVEDS_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getKveds: {
          error,
          loading,
        },
      };
    }

    case CreateEnterpriceActionsTypes.CREATE_ENTERPRICES: {
      return {
        ...state,
        createEnterprice: {
          loading: true,
          error: null,
        },
      };
    }
    case CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_SUCCESS: {
      const { loading } = action;
      return {
        ...state,
        createEnterprice: {
          loading,
          success: true,
        },
      };
    }

    case CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        createEnterprice: {
          error,
          loading,
        },
      };
    }
    case CreateEnterpriceActionsTypes.SET_DEFAULT: {
      return {
        ...state,
        createEnterprice: {
          error: null,
          loading: false,
          success: false,
        },
      };
    }

    case EditEnterpriceActionsTypes.EDIT: {
      return {
        ...state,
        editEnterprice: {
          loading: true,
          error: null,
        },
      };
    }
    case EditEnterpriceActionsTypes.EDIT_SUCCES: {
      const { loading } = action;
      return {
        ...state,
        editEnterprice: {
          loading,
          success: true,
        },
      };
    }

    case EditEnterpriceActionsTypes.EDIT_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        editEnterprice: {
          error,
          loading,
        },
      };
    }
    case EditEnterpriceActionsTypes.SET_DEFAULT: {
      return {
        ...state,
        editEnterprice: {
          error: null,
          loading: false,
          success: false,
        },
      };
    }

    case DeleteEnterpriceActionsTypes.DELETE: {
      return {
        ...state,
        deleteEnterprice: {
          loading: true,
          error: null,
        },
      };
    }
    case DeleteEnterpriceActionsTypes.DELETE_SUCCES: {
      const { loading } = action;
      return {
        ...state,
        deleteEnterprice: {
          loading,
          success: true,
        },
      };
    }

    case DeleteEnterpriceActionsTypes.DELETE_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        deleteEnterprice: {
          error,
          loading,
        },
      };
    }
    default:
      return state;
  }
};

export default enterpricesReducer;
