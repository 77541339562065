import { Routes, Router, Route } from "react-router-dom";
import LoginPage from "./pages/auth/login";
import RegisterPage from "./pages/auth/register";
import DefaultLayout from "./components/containers/default";
import PanelLayout from "./components/containers/panel";
import HomePage from "./pages/home";
import HomePagePanel from "./pages/panel/home";
import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./theme/defaultTheme";

import { BrowserRouter } from "react-router-dom";
import i18n from "./locale/i18n";
import { useActions } from "./hook/useActions";
import Enterprices from "./pages/panel/enterprices";
import CreateEnterprice from "./pages/panel/enterprices/create";
import IncomeData from "./pages/panel/incomeData";
import EditEnterprice from "./pages/panel/enterprices/edit";
import axios from "axios";
import ConfirmGooglePage from "./pages/auth/confirm-google";
import CreateIncomeData from "./pages/panel/incomeData/create";
import EditIncomeData from "./pages/panel/incomeData/edit";
import AssetManagement from "./pages/panel/assetManagement";
import ProfitManagement from "./pages/panel/profitManagment";
import PersonnelManagement from "./pages/panel/personnelManagement";
import CapitalManagment from "./pages/panel/capitalManagment";
import LiquidityAnalysis from "./pages/panel/liquidityAnalysis";
import GraphAnalisys from "./pages/panel/graphAnalysisFVFM";
import BankuptcyAnalysis from "./pages/panel/bankuptcyAnalysis";
import GraphUsing from "./pages/panel/graphCoefUsing";

const baseRouteUrl = "/:locale(uk|en)?";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href"); //+ baseRouteUrl;
const App = () => {
  axios.defaults.headers.common = {
    "Access-Control-Allow-Origin": "*",
  };
  const { checkLoggedIn } = useActions();
  checkLoggedIn();

  const defRoutes = () => {
    return (
      <>
        <Route index element={<HomePage />} />
        <Route path={"login"} element={<LoginPage />} />
        <Route path={"register"} element={<RegisterPage />} />
        <Route path={"confirm-google"} element={<ConfirmGooglePage />} />
        <Route path="*" element={<div>404</div>} />
      </>
    );
  };
  const panelRoutes = () => {
    return (
      <>
        <Route index element={<HomePagePanel />} />
        <Route path="enterprices" element={<Enterprices />} />
        <Route path="enterprices/create" element={<CreateEnterprice />} />
        <Route path="enterprices/edit" element={<EditEnterprice />} />
        <Route path="incomeData" element={<IncomeData />} />
        <Route path="incomeData/create" element={<CreateIncomeData />} />
        <Route path="incomeData/edit/:id" element={<EditIncomeData />} />
        <Route path="assetManagement" element={<AssetManagement />} />
        <Route path="profitManagement" element={<ProfitManagement />} />
        <Route path="personnelManagement" element={<PersonnelManagement />} />
        <Route path="capitalManagment" element={<CapitalManagment />} />
        <Route path="liquidityAnalysis" element={<LiquidityAnalysis />} />
        <Route path="graphAnalysisFVFM" element={<GraphAnalisys />} />
        <Route path="graphCoefUsing" element={<GraphUsing />} />
        <Route path="bankuptcyAnalysis" element={<BankuptcyAnalysis />} />
      </>
    );
  };
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<DefaultLayout />}>
              {defRoutes()}
            </Route>
            <Route path={"uk/"} element={<DefaultLayout />}>
              {defRoutes()}
            </Route>
            <Route path={"en/"} element={<DefaultLayout />}>
              {defRoutes()}
            </Route>

            <Route path={"/panel/"} element={<PanelLayout />}>
              {panelRoutes()}
            </Route>
            <Route path={"uk/panel/"} element={<PanelLayout />}>
              {panelRoutes()}
            </Route>
            <Route path={"en/panel/"} element={<PanelLayout />}>
              {panelRoutes()}
            </Route>
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default App;
