export enum EnterpricesActionsTypes {
    GET_ENTERPRICES = 'GET_ENTERPRICES',
    GET_ENTERPRICES_SUCCESS = 'GET_ENTERPRICES_SUCCESS',
    GET_ENTERPRICES_ERROR = 'GET_ENTERPRICES_ERROR',
    SET_DEFAULT = 'SET_DEFAULT',
}
export enum CreateEnterpriceActionsTypes {
    CREATE_ENTERPRICES = 'CREATE_ENTERPRICES',
    CREATE_ENTERPRICES_SUCCESS = 'CREATE_ENTERPRICES_SUCCESS',
    CREATE_ENTERPRICES_ERROR = 'CREATE_ENTERPRICES_ERROR',
    SET_DEFAULT = 'SET_CREATE_DEFAULT',
}
export enum EditEnterpriceActionsTypes {
    EDIT = 'EDIT_ENTERPRICES',
    EDIT_SUCCES = 'EDIT_ENTERPRICES_SUCCESS',
    EDIT_ERROR = 'EDIT_ENTERPRICES_ERROR',
    SET_DEFAULT = 'SET_EDIT_DEFAULT',
}
export enum DeleteEnterpriceActionsTypes {
    DELETE = 'DELETE_ENTERPRICES',
    DELETE_SUCCES = 'DELETE_ENTERPRICES_SUCCESS',
    DELETE_ERROR = 'DELETE_ENTERPRICES_ERROR',
    SET_DEFAULT = 'SET_DELETE_DEFAULT',
}
export enum KvedsActionsTypes {
    GET_KVEDS = 'GET_KVEDS',
    GET_KVEDS_SUCCESS = 'GET_KVEDS_SUCCESS',
    GET_KVEDS_ERROR = 'GET_KVEDS_ERROR',
    SET_DEFAULT = 'SET_DEFAULT',
}
export interface IEnterpricesState {
    enterprices: [];
    kveds:[];
    getEnterprices:{
        loading: boolean;
        error: null | string;
        success: boolean;
    }
    createEnterprice:{
        loading: boolean;
        error: null | string;
        success: boolean;
    }
    editEnterprice:{
        loading: boolean;
        error: null | string;
        success: boolean;
    }
    getKveds:{
        loading: boolean;
        error: null | string;
        success: boolean;
    },
    deleteEnterprice:{
        loading: boolean;
        error: null | string;
        success: boolean;
    }
}
export interface IGetEnterpriceResult {
    data:[],
    success:boolean,
    error:string
}
export interface IResult {
    success:boolean,
    error:string
}
export interface IEnterpricesPost{
    id? : number,
    name: string,
    registrationNumber: string,
    cityName:string,
    cityRef: string,
    adressName: string,
    adressRef: string,
    kvedGroups:[]
}

export interface EnterpricesRequest {
    type: EnterpricesActionsTypes.GET_ENTERPRICES,
}
export interface EnterpricesRequestSucces {
    type: EnterpricesActionsTypes.GET_ENTERPRICES_SUCCESS,
    enterprices: [];
    loading: boolean;
}

export interface EnterpricesRequestError {
    type: EnterpricesActionsTypes.GET_ENTERPRICES_ERROR,
    error:string,
    loading:boolean
}
export interface EnterpricesSetDefault {
    type: EnterpricesActionsTypes.SET_DEFAULT
}

export interface GetKvedRequest {
    type: KvedsActionsTypes.GET_KVEDS,
}
export interface GetKvedRequestSucces {
    type: KvedsActionsTypes.GET_KVEDS_SUCCESS,
    kveds: [];
    loading: boolean;
}

export interface GetKvedRequestError {
    type: KvedsActionsTypes.GET_KVEDS_ERROR,
    error:string,
    loading:boolean
}

export interface CreateEnterpricesRequest {
    type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES,
    model:IEnterpricesPost
}
export interface CreateEnterpricesRequestSucces {
    type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_SUCCESS,
    loading: boolean;
}

export interface CreateEnterpricesRequestRequestError {
    type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_ERROR,
    error:string,
    loading:boolean
}
export interface CreateEnterpricesRequestSetDefault {
    type: CreateEnterpriceActionsTypes.SET_DEFAULT
}


export interface EditEnterpricesRequest {
    type: EditEnterpriceActionsTypes.EDIT,
    model:IEnterpricesPost
}
export interface EditEnterpricesRequestSucces {
    type: EditEnterpriceActionsTypes.EDIT_SUCCES,
    loading: boolean;
}

export interface EditEnterpricesRequesError {
    type: EditEnterpriceActionsTypes.EDIT_ERROR,
    error:string,
    loading:boolean
}
export interface EditEnterpricesRequestSetDefault {
    type: EditEnterpriceActionsTypes.SET_DEFAULT
}


export interface DeleteEnterpricesRequest {
    type: DeleteEnterpriceActionsTypes.DELETE,
    model:IEnterpricesPost
}
export interface DeleteEnterpricesRequestSucces {
    type: DeleteEnterpriceActionsTypes.DELETE_SUCCES,
    loading: boolean;
}

export interface DeleteEnterpricesRequesError {
    type: DeleteEnterpriceActionsTypes.DELETE_ERROR,
    error:string,
    loading:boolean
}

export type EnterpriceActions = EnterpricesRequest | EnterpricesRequestSucces | EnterpricesRequestError | EnterpricesSetDefault |
GetKvedRequest| GetKvedRequestSucces| GetKvedRequestError | CreateEnterpricesRequest | CreateEnterpricesRequestSucces | CreateEnterpricesRequestRequestError 
| CreateEnterpricesRequestSetDefault | EditEnterpricesRequest | EditEnterpricesRequestSucces | EditEnterpricesRequesError |
EditEnterpricesRequestSetDefault | DeleteEnterpricesRequest | DeleteEnterpricesRequestSucces | DeleteEnterpricesRequesError;