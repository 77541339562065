import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { history } from "../store/index";

import languageUK from "./translations/uk";
import languageEN from "./translations/en";


i18n.on("languageChanged", function (lng) {
  let finish = false;
  if (!window.location.pathname.includes("/" + lng) ) {
    
    if(lng == "uk" && !window.location.pathname.includes("/en")){
      finish = true;
    }
    if ( window.location.pathname.includes("/en/") && lng == "uk" ) {
      const newUrl = window.location.pathname.replace(
        "/en",
        ""
      );
      //console.log("new",newUrl)
      //window.history.replaceState(null, "", newUrl);
      window.location.replace(newUrl);
      finish = true;
    }
    console.log("finis", finish)
    if (!finish) {
      // window.history.replaceState(
      //   null,
      //   "",
      //   "/" + lng + window.location.pathname
      // );
      window.location.replace("/" + lng + window.location.pathname);
    }
  }
});


i18n
  //.use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      uk: languageUK,
      //ru: languageRU,
    },
    //lng:"uk",
    otherLanguages: ["uk","en"],
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
    fallbackLng: ["uk"],
    defaultLocale: 'uk',
    locales: ['uk', 'en'],
    whitelist: ["uk","en"],
    detection: {
      order: ['localStorage'],
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

export default i18n;
