import { findLoginRequest } from './actions';
import {
  LoginActionsTypes,
  IUserState,
  UserActions,
  RegisterActionsTypes,
  GoogleActionsTypes,
} from "./types";

const initialState: IUserState = {
  isLoggedIn: false,
  user: undefined,
  login: {
    loading: false,
    error: null,
    success: false,
  },
  register: {
    loading: false,
    error: null,
    success: false,
  },
  findLogin: {
    success: false,
    token: null
  }
};

const userReducer = (state: IUserState = initialState, action: UserActions) => {
  switch (action.type) {
    case LoginActionsTypes.POST_LOGIN: {
      return {
        ...state,
        login: {
          loading: true,
          error: null,
        },
      };
    }
    case LoginActionsTypes.POST_LOGIN_SUCCESS: {
      const { loading, user } = action;
      return {
        ...state,
        user,
        isLoggedIn: true,
        login: {
          loading,
          success: true,
        },
      };
    }

    case LoginActionsTypes.POST_LOGIN_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        login: {
          error,
          loading,
        }
      };
    }
    case LoginActionsTypes.SET_DEFAULT: {
      return {
        ...state,
        login: {
          error: null,
          loading: false,
          success: false,
        },
        findLogin: {
          success: false,
          token: null
        }
      };
    }
    case LoginActionsTypes.CHECK_SUCCESS: {
      const { user } = action;
      return {
        ...state,
        isLoggedIn: true,
        user,
      };
    }
    case LoginActionsTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        user: undefined,
      };
    }

    case RegisterActionsTypes.POST_REGISTER: {
      return {
        ...state,
        register: {
          loading: true,
          error: null,
        },
      };
    }
    case RegisterActionsTypes.POST_REGISTER_SUCCESS: {
      const { loading, success } = action;
      return {
        ...state,
        register: {
          loading,
          success,
        },
      };
    }

    case RegisterActionsTypes.POST_REGISTER_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        register: {
          error,
          loading,
        },
      };
    }
    case RegisterActionsTypes.SET_DEFAULT: {
      return {
        ...state,
        register: {
          error: null,
          loading: false,
          success: false,
        },
      };
    }

    case GoogleActionsTypes.POST_FIND_LOGIN: {
      return {
        ...state,
        login: {
          loading: true,
          error: null,
        },
      };
    }

    case GoogleActionsTypes.POST_FIND_LOGIN_NOTFOUND: {
      //const { error, loading } = action;
      //console.log()
      return {
        ...state,
        login: {
          //error,
          loading: false,
        },
        findLogin : {
          success: false,
          token: action.tokenId
        }
      };
    }

    default:
      return state;
  }
};

export default userReducer;
