import { useEffect } from "react";
import { Box, Grid } from "@mui/material";

const HomePage = () => {

  // const { getMoviesRequest } = useActions();
  // const { movies } = useTypedSelector(state => state.movies);

  useEffect(() => {
    //getMoviesRequest(1);
  }, []);

  return (
    <>
      <Box >

      </Box>
    </>
  );
};

export default HomePage;
