import { Backdrop, Box, Button, Card, Grid, TableRow, CircularProgress, TextField, MenuItem, CardContent, Typography, TableCell, TableHead, Table, TableContainer, TableBody } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useActions } from "../../../hook/useActions";
import { number } from "yup";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import moment from 'moment';

const AssetManagement = () => {
    const { t } = useTranslation();
    const { getIncomeDataRequest, getAssesments } = useActions();
    const { incomeDatas, assesment } = useTypedSelector<any>(state => state.incomeData);

    const [incomeData, setIncomeData] = useState(0);
    useEffect(() => {
        if (incomeDatas.length == 0) {
            getIncomeDataRequest();
        }
    }, [])
    useEffect(() => {
        if (incomeData != 0) {
            getAssesments(incomeData);
        }
    }, [incomeData]);
    console.log("assesment", assesment);
    return (
        <>
            {/* <Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} style={{ paddingTop: "8px" }}>
                        <TextField
                            select
                            sx={{ width: "100%" }}
                            label={t("assetManagment:selectIncomeData")}
                            id="selectedEnterprise"
                            name="selectedEnterprise"
                            value={incomeData}
                            onChange={(id) => { setIncomeData(parseInt(id.target.value)) }}
                        //value={selectedEnterprise}
                        //onChange={(el) => setSelectedEnterprise(+el.target.value)}
                        >
                            {incomeDatas.map((el: any) =>
                                <MenuItem key={el.id} value={el.id}>
                                    {moment(el.startPeriodDate).format("DD.MM.yyyy")} - {moment(el.endPeriodDate).format("DD.MM.yyyy")}
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    {assesment.oser != undefined ? <>
                        <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            <TableContainer >
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "bold" }}>{t("assetManagment:title")}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{t("assetManagment:value")}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{t("assetManagment:description")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {t("assetManagment:noncurrentAssets")}
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:oserTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">{assesment.oser}</TableCell>
                                            <TableCell>{t("assetManagment:oserSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:fmTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.fm}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:fmSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:fvTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.fv}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:fvSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:kzTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.kz}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:kzSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:kpTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.kp}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:kpSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:koTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.ko}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:koSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {t("assetManagment:currentAssets")}
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:vokTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.vok}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:vokSubtitle")}</TableCell>
                                        </TableRow>
                                        
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:kzvokTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.kzvok}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:kzvokSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:kmvosTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.kmvos}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:kmvosSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:ksTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.ks}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:ksSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:kvTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.kv}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:kvSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:zoaTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.zoa}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:zoaSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:kpPTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.kpP}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:kpPSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:ROATitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.roa}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:ROASubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:tobTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.tob}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:tobSubtitle")}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                {t("assetManagment:assesmentSpeedTitle")}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                {assesment.assesmentSpeed}
                                            </TableCell>
                                            <TableCell>{t("assetManagment:assesmentSpeedSubtitle")}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>{t("assetManagment:noncurrentAssets")}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:oserTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.oser}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:oserSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:fmTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.fm}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:fmSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:fvTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.fv}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:fvSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:kzTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.kz}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:kzSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:kpTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.kp}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:kpSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:koTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.ko}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:koSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>{t("assetManagment:currentAssets")}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:kvTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.kv}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:kvSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:kzvokTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.kzvok}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:kzvokSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:kmvosTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.kmvos}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:kmvosSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:ksTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.ks}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:ksSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:zoaTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.zoa}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:zoaSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:kpPTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.kpP}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:kpPSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:ROATitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.roa}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:ROASubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:tobTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.tob}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:tobSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                        {t("assetManagment:assesmentSpeedTitle")}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                        {t("assetManagment:value") + ": " + assesment.assesmentSpeed}
                                    </Typography>
                                    <Typography component="div" color="text.primary">
                                        {t("assetManagment:assesmentSpeedSubtitle")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                        :
                        <></>}
                </Grid>
            </Box>
        </>
    )
}
export default AssetManagement;