export enum URLs {
  LOGIN_ENDPOINT = "api/authorization/login",
  FIND_LOGIN_ENDPOINT = "api/authorization/find-login",
  GOOGLE_LOGIN_ENDPOINT = "api/authorization/google-login",
  REFRESH_ENDPOINT = "api/authorization/refresh",
  REGISTER_ENDPOINT = "api/authorization/register",

  GET_ENTERPRICES_ENDPOINT = "api/enterprises/get",
  GET_KVEDS_ENDPOINT = "/api/kveds/get/",
  CREATE_KVED_ENDPOINT = "/api/enterprises/create",
  EDIT_ENTERPRICE_ENDPOINT = "/api/enterprises/edit",
  DELETE_ENTERPRICE_ENDPOINT = "/api/enterprises/delete/",

  GET_INCOMEDATA_CODES_ENDPOINT = "/api/incomedata/getrowcodes",
  GET_INCOMEDATA_ENDPOINT = "/api/incomedata/getlist",

  GET_ASSETSMANAGMENT_ENDPOINT = "/api/incomedata/getassetsmanagment/",
  GET_PROFITMANAGMENT_ENDPOINT = "/api/incomedata/getprofitmanagment/",
  GET_PERSONNELMANAGMENT_ENDPOINT = "/api/incomedata/getpersonnelmanagement/",
  GET_CAPITAL_MANAGMENT_ENDPOINT = "/api/incomedata/getcapitalmanagement/",
  GET_LIQUIDITY_ANALYSIS_ENDPOINT = "/api/incomedata/getliquidityanalysis/",
  GET_BANKUPTCY_ANALYSIS_ENDPOINT = "/api/incomedata/getbankruptcyanalysis/",

  GET_GRAPH_ANALYSIS_FV_TO_FM = "/api/graphs/analisys-tm-to-fv/",
  GET_GRAPH_COEF_USING = "/api/graphs/coef-using-fonds-query/",

  CREATE_INCOMEDATA_ENDPOINT = "/api/incomedata/create",
  DELETE_INCOMEDATA_ENDPOINT = "/api/incomedata/delete/",
  EDIT_INCOMEDATA_ENDPOINT = "/api/incomedata/edit/",
}
