import { takeLatest, put, call, select } from "redux-saga/effects";
import { apiRequest } from "../../apiRequest";
import { URLs } from "../../urls";
import {
  CreateTypes,
  CreateRequestError,
  CreateRequestSucces,
  GetCodesRequestError,
  GetCodesRequestSucces,
  IGetIncomeDataResult,
  IResult,
  GetListRowCodesTypes,
  DeleteTypes,
  GetListTypes,
  IncomeDatasRequest,
  IncomeDatasRequestSucces,
  IncomeDatasRequestError,
  DeleteRequestSucces,
  DeleteRequestError,
  GetEditTypes,
  IGetEditResult,
  GetEditRequestSucces,
  GetEditRequestError,
  EditRequestSucces,
  EditRequestError,
  EditTypes,
  GetAssesmentTypes,
  GetAssesmentSucces,
  GetAssesmentError,
  GetProfitTypes,
  GetProfitSucces,
  GetProfitError,
  GetPersonnelSucces,
  GetPersonnelTypes,
  GetPersonnelError,
} from "./types";

function* getCodesRequest(action: any) {
  try {
    // API call
    const request: IGetIncomeDataResult = yield call(apiRequest, {
      endpoint: URLs.GET_INCOMEDATA_CODES_ENDPOINT,
      method: "GET",
    });
    if (request.success) {
      let putData: GetCodesRequestSucces = {
        type: GetListRowCodesTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<GetCodesRequestSucces>(putData);
    } else {
      let putData: GetCodesRequestError = {
        type: GetListRowCodesTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetCodesRequestError>(putData);
    }
  } catch (e: any) {
    let putData: GetCodesRequestError = {
      type: GetListRowCodesTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetCodesRequestError>(putData);
  }
}
function* getListRequest(action: any) {
  try {
    // API call
    const request: IGetIncomeDataResult = yield call(apiRequest, {
      endpoint: URLs.GET_INCOMEDATA_ENDPOINT,
      method: "GET",
    });
    if (request.success) {
      let putData: IncomeDatasRequestSucces = {
        type: GetListTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<IncomeDatasRequestSucces>(putData);
    } else {
      let putData: IncomeDatasRequestError = {
        type: GetListTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<IncomeDatasRequestError>(putData);
    }
  } catch (e: any) {
    let putData: IncomeDatasRequestError = {
      type: GetListTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<IncomeDatasRequestError>(putData);
  }
}
function* createRequest(action: any) {
  try {
    // API call
    const request: IResult = yield call(apiRequest, {
      endpoint: URLs.CREATE_INCOMEDATA_ENDPOINT,
      method: "POST",
      body: action.model,
    });
    if (request.success) {
      let putData: CreateRequestSucces = {
        type: CreateTypes.CREATE_SUCCESS,
        loading: false,
      };
      yield put<CreateRequestSucces>(putData);
    } else {
      let putData: CreateRequestError = {
        type: CreateTypes.CREATE_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<CreateRequestError>(putData);
    }
  } catch (e: any) {
    let putData: CreateRequestError = {
      type: CreateTypes.CREATE_ERROR,
      error: e,
      loading: false,
    };
    yield put<CreateRequestError>(putData);
  }
}
function* deleteRequest(action: any) {
  try {
    // API call
    const request: IResult = yield call(apiRequest, {
      endpoint: URLs.DELETE_INCOMEDATA_ENDPOINT + action.id,
      method: "DELETE",
    });
    if (request.success) {
      let putData: DeleteRequestSucces = {
        type: DeleteTypes.DELETE_SUCCES,
        loading: false,
      };
      yield put<DeleteRequestSucces>(putData);
    } else {
      let putData: DeleteRequestError = {
        type: DeleteTypes.DELETE_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<DeleteRequestError>(putData);
    }
  } catch (e: any) {
    let putData: DeleteRequestError = {
      type: DeleteTypes.DELETE_ERROR,
      error: e,
      loading: false,
    };
    yield put<DeleteRequestError>(putData);
  }
}
function* getAssesmentRequest(action: any) {
  try {
    // API call
    const request: IGetEditResult = yield call(apiRequest, {
      endpoint: URLs.GET_ASSETSMANAGMENT_ENDPOINT + action.id,
      method: "GET",
    });
    if (request.success) {
      let putData: GetAssesmentSucces = {
        type: GetAssesmentTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<GetAssesmentSucces>(putData);
    } else {
      let putData: GetAssesmentError = {
        type: GetAssesmentTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetAssesmentError>(putData);
    }
  } catch (e: any) {
    let putData: GetAssesmentError = {
      type: GetAssesmentTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetAssesmentError>(putData);
  }
}
function* getProfitRequest(action: any) {
  try {
    // API call
    const request: IGetEditResult = yield call(apiRequest, {
      endpoint: URLs.GET_PROFITMANAGMENT_ENDPOINT + action.id,
      method: "GET",
    });
    if (request.success) {
      let putData: GetProfitSucces = {
        type: GetProfitTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<GetProfitSucces>(putData);
    } else {
      let putData: GetProfitError = {
        type: GetProfitTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetProfitError>(putData);
    }
  } catch (e: any) {
    let putData: GetProfitError = {
      type: GetProfitTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetProfitError>(putData);
  }
}
function* getEditRequest(action: any) {
  try {
    // API call
    const request: IGetEditResult = yield call(apiRequest, {
      endpoint: URLs.EDIT_INCOMEDATA_ENDPOINT + action.id,
      method: "GET",
    });
    if (request.success) {
      let putData: GetEditRequestSucces = {
        type: GetEditTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<GetEditRequestSucces>(putData);
    } else {
      let putData: GetEditRequestError = {
        type: GetEditTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetEditRequestError>(putData);
    }
  } catch (e: any) {
    let putData: GetEditRequestError = {
      type: GetEditTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetEditRequestError>(putData);
  }
}
function* editRequest(action: any) {
  try {
    // API call
    const request: IResult = yield call(apiRequest, {
      endpoint: URLs.EDIT_INCOMEDATA_ENDPOINT + action.id,
      method: "PUT",
      body: action.model,
    });
    if (request.success) {
      let putData: EditRequestSucces = {
        type: EditTypes.EDIT_SUCCES,
        loading: false,
      };
      yield put<EditRequestSucces>(putData);
    } else {
      let putData: EditRequestError = {
        type: EditTypes.EDIT_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<EditRequestError>(putData);
    }
  } catch (e: any) {
    let putData: EditRequestError = {
      type: EditTypes.EDIT_ERROR,
      error: e,
      loading: false,
    };
    yield put<EditRequestError>(putData);
  }
}

function* getPersonnelRequest(action: any) {
  try {
    // API call
    const request: IGetEditResult = yield call(apiRequest, {
      endpoint: URLs.GET_PERSONNELMANAGMENT_ENDPOINT + action.id,
      method: "GET",
    });
    if (request.success) {
      let putData: GetPersonnelSucces = {
        type: GetPersonnelTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<GetPersonnelSucces>(putData);
    } else {
      let putData: GetPersonnelError = {
        type: GetPersonnelTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetPersonnelError>(putData);
    }
  } catch (e: any) {
    let putData: GetPersonnelError = {
      type: GetPersonnelTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetPersonnelError>(putData);
  }
}

export function* handler() {
  yield takeLatest(GetListTypes.GET, getListRequest);
  yield takeLatest(GetListRowCodesTypes.GET, getCodesRequest);
  yield takeLatest(CreateTypes.CREATE, createRequest);
  yield takeLatest(DeleteTypes.DELETE, deleteRequest);
  yield takeLatest(GetEditTypes.GET, getEditRequest);
  yield takeLatest(EditTypes.EDIT, editRequest);
  yield takeLatest(GetAssesmentTypes.GET, getAssesmentRequest);
  yield takeLatest(GetProfitTypes.GET, getProfitRequest);
  yield takeLatest(GetPersonnelTypes.GET, getPersonnelRequest);
}
