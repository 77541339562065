import { createTheme } from '@mui/material/styles';
import { ukUA as coreDeDE } from '@mui/material/locale';
import { ukUA } from '@mui/x-date-pickers/locales';
export const defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3a0ca3',
      dark: '#3f37c9',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#811B7E',
      dark: '#7209b7',
      contrastText: '#000',
    }
  },
  
},ukUA,// x-data-grid translations
coreDeDE,);