import {combineReducers} from '@reduxjs/toolkit';
import userReducer from './user/reducer';
import enterpricesReducer from './enterprices/reducer';
import incomeDataReducer from './incomeData/reducer';
import capitalManagmentReducer from './capitalManagment/reducer';
import liquidityAnalysisReducer from './liquidityAnalysis/reducer';
import graphAnalysisFVFMReducer from './graphAnalysisFVFM/reducer';
import bankuptcyAnalysisReducer from "./bankuptcyAnalysis/reducer";
import coefUsingReducer from './graphCoefUsing/reducer';

export const reducer = combineReducers({
	user: userReducer,
	enterprices: enterpricesReducer,
	incomeData: incomeDataReducer,
	capitalManagment: capitalManagmentReducer,
	liquidityAnalysis: liquidityAnalysisReducer,
	graphAnalysisFVFM: graphAnalysisFVFMReducer,
	graphCoefUsing: coefUsingReducer,
	bankuptcyAnalysis: bankuptcyAnalysisReducer,
});
