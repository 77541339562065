import axios from "axios";
import { APP_ENV } from "./env";

const http= axios.create({
    baseURL: APP_ENV.REMOTE_HOST_NAME,
    headers: {
        'Accept': 'application/json',
        "Content-type": "application/json"
      }
});

//myaxios.interceptors

export default http;