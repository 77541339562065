import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { URLs } from '../../store/urls';
import { useTypedSelector } from "../../hook/useTypedSelector";
import { useActions } from "../../hook/useActions";

const HomePage = () => {

  // const { getMoviesRequest } = useActions();
  // const { movies } = useTypedSelector(state => state.movies);

  useEffect(() => {
    //getMoviesRequest(1);
  }, []);

  return (
    <>
      <Box  sx={{height: '100vh',backgroundImage:"url("+require("../../assets/img/home_background.png")+")"}}>

      </Box>
    </>
  );
};

export default HomePage;
