export enum GetListRowCodesTypes {
  GET = "GET_INCOMEDATACODES",
  GET_SUCCESS = "GET_INCOMEDATACODES_SUCCESS",
  GET_ERROR = "GET_INCOMEDATACODES_ERROR",
}
export enum GetEditTypes {
  GET = "GET_INCOME_EDIT",
  GET_SUCCESS = "GET_INCOME_EDIT_SUCCESS",
  GET_ERROR = "GET_INCOME_EDIT_ERROR",
}
export enum GetSingleTypes {
  GET = "GET_SINGLE_INCOMEDATA",
  GET_SUCCESS = "GET_SINGLE_INCOMEDATA_SUCCESS",
  GET_ERROR = "GET_SINGLE_INCOMEDATA_ERROR",
}
export enum GetListTypes {
  GET = "GET_INCOMEDATA",
  GET_SUCCESS = "GET_INCOMEDATA_SUCCESS",
  GET_ERROR = "GET_INCOMEDATA_ERROR",
}
export enum GetAssesmentTypes {
  GET = "GET_ASSESMENTS",
  GET_SUCCESS = "GET_ASSESMENTS_SUCCESS",
  GET_ERROR = "GET_ASSESMENTS_ERROR",
}
export enum GetProfitTypes {
  GET = "GET_PROFIT",
  GET_SUCCESS = "GET_PROFIT_SUCCESS",
  GET_ERROR = "GET_PROFIT_ERROR",
}

export enum GetPersonnelTypes {
  GET = "GET_PERSONNEL",
  GET_SUCCESS = "GET_PERSONNEL_SUCCESS",
  GET_ERROR = "GET_PERSONNEL_ERROR",
}

export enum CreateTypes {
  CREATE = "CREATE_INCOMEDATA",
  CREATE_SUCCESS = "CREATE_INCOMEDATA_SUCCESS",
  CREATE_ERROR = "CREATE_INCOMEDATA_ERROR",
  SET_DEFAULT = "SET_CREATE_DEFAULT",
}
export enum EditTypes {
  EDIT = "EDIT_INCOMEDATA",
  EDIT_SUCCES = "EDIT_INCOMEDATA_SUCCESS",
  EDIT_ERROR = "EDIT_INCOMEDATA_ERROR",
  SET_DEFAULT = "SET_EDIT_DEFAULT",
}
export enum DeleteTypes {
  DELETE = "DELETE_INCOMEDATA",
  DELETE_SUCCES = "DELETE_INCOMEDATA_SUCCESS",
  DELETE_ERROR = "DELETE_INCOMEDATA_ERROR",
  SET_DEFAULT = "SET_DELETE_DEFAULT",
}

export interface IIncomeDataState {
  codes: [];
  incomeDatas: [];
  assesment: {};
  profit: {};
  personnel: {};
  getIncomeData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  createIncomeData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  getEditIncomeData: {
    data: {};
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  editIncomeData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  getCodes: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  deleteIncomeData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  getAssesments: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  getProfit: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  getPersonnel: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
}
export interface IGetIncomeDataResult {
  data: [];
  success: boolean;
  error: string;
}
export interface IGetEditResult {
  data: {};
  success: boolean;
  error: string;
}
export interface IResult {
  success: boolean;
  error: string;
}
export interface IIncomeDataPost {
  id?: number;
  enterpriseId: number;
  averageWorkersCount: number;
  startPeriodDate: string;
  endPeriodDate: string;
  rows: [];
}

export interface IncomeDatasRequest {
  type: GetListTypes.GET;
}
export interface IncomeDatasRequestSucces {
  type: GetListTypes.GET_SUCCESS;
  data: [];
  loading: boolean;
}

export interface IncomeDatasRequestError {
  type: GetListTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export interface GetCodesRequest {
  type: GetListRowCodesTypes.GET;
}
export interface GetCodesRequestSucces {
  type: GetListRowCodesTypes.GET_SUCCESS;
  data: [];
  loading: boolean;
}

export interface GetCodesRequestError {
  type: GetListRowCodesTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export interface CreateRequest {
  type: CreateTypes.CREATE;
  model: IIncomeDataPost;
}
export interface CreateRequestSucces {
  type: CreateTypes.CREATE_SUCCESS;
  loading: boolean;
}

export interface CreateRequestError {
  type: CreateTypes.CREATE_ERROR;
  error: string;
  loading: boolean;
}
export interface CreateRequestSetDefault {
  type: CreateTypes.SET_DEFAULT;
}

export interface GetEditRequest {
  type: GetEditTypes.GET;
}
export interface GetEditRequestSucces {
  type: GetEditTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetEditRequestError {
  type: GetEditTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export interface EditRequest {
  type: EditTypes.EDIT;
  id: number;
  model: IIncomeDataPost;
}
export interface EditRequestSucces {
  type: EditTypes.EDIT_SUCCES;
  loading: boolean;
}

export interface EditRequestError {
  type: EditTypes.EDIT_ERROR;
  error: string;
  loading: boolean;
}
export interface EditRequestSetDefault {
  type: EditTypes.SET_DEFAULT;
}

export interface DeleteRequest {
  type: DeleteTypes.DELETE;
  id: number;
}
export interface DeleteRequestSucces {
  type: DeleteTypes.DELETE_SUCCES;
  loading: boolean;
}

export interface DeleteRequestError {
  type: DeleteTypes.DELETE_ERROR;
  error: string;
  loading: boolean;
}

export interface GetAssesmentRequest {
  type: GetAssesmentTypes.GET;
}
export interface GetAssesmentSucces {
  type: GetAssesmentTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetAssesmentError {
  type: GetAssesmentTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export interface GetProfitRequest {
  type: GetProfitTypes.GET;
}
export interface GetProfitSucces {
  type: GetProfitTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetProfitError {
  type: GetProfitTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export interface GetPersonnelRequest {
  type: GetPersonnelTypes.GET;
}
export interface GetPersonnelSucces {
  type: GetPersonnelTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetPersonnelError {
  type: GetPersonnelTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export type IncomeDataActions =
  | IncomeDatasRequest
  | IncomeDatasRequestSucces
  | IncomeDatasRequestError
  | GetCodesRequest
  | GetCodesRequestSucces
  | GetCodesRequestError
  | CreateRequest
  | CreateRequestSucces
  | CreateRequestError
  | CreateRequestSetDefault
  | DeleteRequest
  | DeleteRequestSucces
  | DeleteRequestError
  | GetEditRequest
  | GetEditRequestSucces
  | GetEditRequestError
  | EditRequest
  | EditRequestSucces
  | EditRequestError
  | EditRequestSetDefault
  | GetAssesmentRequest
  | GetAssesmentSucces
  | GetAssesmentError
  | GetProfitRequest
  | GetProfitSucces
  | GetProfitError
  | GetPersonnelRequest
  | GetPersonnelSucces
  | GetPersonnelError;
