
export enum GetGraphAnalysisFVFMTypes {
  GET = "GET_GRAPH_ANALYSIS_FVFM",
  GET_SUCCESS = "GET_GRAPH_ANALYSIS_FVFM_SUCCESS",
  GET_ERROR = "GET_GRAPH_ANALYSIS_FVFM_ERROR",
}

export interface IGraphAnalysisFVFMState {
  data: [];
  getData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
}
export interface IGetGraphAnalysisFVFMResult {
  data: [];
  success: boolean;
  error: string;
}
export interface IGetEditResult {
  data: {items:[]};
  success: boolean;
  error: string;
}
export interface IResult {
  success: boolean;
  error: string;
}

export interface GetGraphAnalysisFVFMRequest {
  type: GetGraphAnalysisFVFMTypes.GET;
}
export interface GetGraphAnalysisFVFMSucces {
  type: GetGraphAnalysisFVFMTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetGraphAnalysisFVFMError {
  type: GetGraphAnalysisFVFMTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export type GraphAnalysisFVFMActions =
  | GetGraphAnalysisFVFMRequest
  | GetGraphAnalysisFVFMSucces
  | GetGraphAnalysisFVFMError;
