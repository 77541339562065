import { call, put } from "redux-saga/effects";
import { AxiosResponse, AxiosRequestHeaders } from "axios";
import http from "../http_common";
import {
  ITokenData,
  LoginActionsTypes,
  Logout,
  PostLoginRequestSucces,
} from "./reducers/user/types";
import { URLs } from "./urls";
import { logout } from "./reducers/user/saga";
import jwtDecode from "jwt-decode";

export interface IFetchOptions {
  endpoint: string;
  method: string;
  headers: AxiosRequestHeaders;
  body: any;
}

const makeRequest = async ({ endpoint, method, headers, body = null }: any) => {
  const response = await http
    .request({
      url: endpoint,
      method,
      headers,
      data: body,
    })
    .catch((err) => {
      return err.response;
    });
  return response;
};

const callRefresh = async () => {
  const response: AxiosResponse = await http
    .request({
      url: URLs.REFRESH_ENDPOINT,
      method: "POST",
      data: {
        token: localStorage.accessToken,
        refreshToken: localStorage.refreshToken,
      },
    })
    .catch((err) => {
      return err.response;
    });
  return response;
};

function* apiRequest({ endpoint, method, body = null }: any) {
  //  FOR USER AUTHORIZATION
  //const state = yield select();

  const JWT = localStorage.accessToken; //"eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI2MjhlY2U0Mjc3YjhlZTAxMGJkMDMwZDEzMmI4MDU0ZSIsInN1YiI6IjVlMDRjYTdiZDFhODkzMDAxOTk0OGE4MyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.UcAcn8Bx7Obm_iQ2btMGRRv-8eRV6gij9vpbAvLfbpM";

  const response: AxiosResponse = yield call(makeRequest, {
    endpoint,
    method,
    headers: {
      //  FOR USER AUTHORIZATION
      //Authorization: state.user.authToken ? `Bearer ${ state.user.authToken }` : null,
      //  TEST JWT FOR TEST API
      Authorization: `Bearer ${JWT}`,
    },
    body: body !== null ? JSON.stringify({ ...body }) : body,
  });

  console.log(response);
  if (response.status === 401) {
    let r: AxiosResponse = yield call(callRefresh);
    if (r.data.success) {
      let loginRes = r.data;
      localStorage.accessToken = loginRes.data.token;
      localStorage.refreshToken = loginRes.data.refreshToken;
      let putData: PostLoginRequestSucces = {
        type: LoginActionsTypes.POST_LOGIN_SUCCESS,
        user: jwtDecode<ITokenData>(loginRes.data.token),
        loading: false,
      };
      yield put<PostLoginRequestSucces>(putData);
      const newR: AxiosResponse = yield call(makeRequest, {
        endpoint,
        method,
        headers: {
          Authorization: `Bearer ${loginRes.data.token}`,
        },
        body: body !== null ? JSON.stringify({ ...body }) : body,
      });
      return newR.data;
    } else {
      yield call(logout);
    }
    // if(r..success){
    // 	apiRequest(endpoint, method, body)
    // }
    console.log("401");
  }

  if (response.status === 400) {
    // handle bad response
    console.error(response);
    throw new Error("not ok api request in apiRequest func");
  }
  return response.data;
}

export { apiRequest };
