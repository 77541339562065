import { Backdrop, Box, Button, Card, Grid, TableRow, CircularProgress, TextField, MenuItem, CardContent, Typography, TableCell, TableHead, Table, TableContainer, TableBody } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useActions } from "../../../hook/useActions";
import { number } from "yup";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import moment from 'moment';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { CSVLink } from "react-csv"
import font from "../../../assets/fonts/font.js";
const ProfitManagement = () => {
    const { t } = useTranslation();
    const { getIncomeDataRequest, getProfitRequest } = useActions();
    const { incomeDatas, profit } = useTypedSelector<any>(state => state.incomeData);

    const [incomeData, setIncomeData] = useState(0);
    const data = [
        { name: "vageZV" },
        { name: "vagePV" },
        { name: "vm" },
        { name: "kvm" },
        { name: "recommendReserveCapital" },
        { name: "prValue" },
        { name: "prCoefficient" },
        { name: "zfnValue" },
        { name: "zfnPercent" },
        { name: "zfnCoefficientOperational" },
        { name: "zfnEffectOperational" },
        { name: "ros" },
        { name: "roa" },
    ];
    const exportPDF = async () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "p"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = t("panelMenu:profitManagement")!;
        console.log("title", title)
        const headers = [[t("profitManagment:title"), t("profitManagment:value"), t("profitManagment:description")]];

        const ndata = data.map((el: any) => [t(`profitManagment:${el.name}:title`), profit[el.name], t(`profitManagment:${el.name}:subtitle`)]);

        let content = {
            startY: 50,
            head: headers,
            body: ndata
        };
        console.log("br",font)
        doc.addFileToVFS('Roboto-Black.ttf',font); // addfont
        doc.addFont('Roboto-Black.ttf', 'Roboto', 'normal');
        //doc.setFont('MyFont');
        doc.addFont('Roboto-Black', 'Roboto', 'normal');
        doc.setFont('Roboto');
        //doc.setFontType("normal");
        doc.setFontSize(28);
        doc.text(title, marginLeft, 40);
        //autoTable(doc, content);
        let ic = incomeDatas.find((x: any) => x.id == incomeData);
        doc.save(`${t("panelMenu:profitManagement").replace(" ", "_")}_${moment(ic.startPeriodDate).format("DD.MM.yyyy") + "-" + moment(ic.endPeriodDate).format("DD.MM.yyyy")}.pdf`)
    }
    let ic = incomeDatas?.find((x: any) => x.id == incomeData);
    useEffect(() => {
        if (incomeDatas.length == 0) {
            getIncomeDataRequest();
        }
    }, [])
    useEffect(() => {
        if (incomeData != 0) {
            getProfitRequest(incomeData);
        }
    }, [incomeData]);
    return (
        <>
            {/* <Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} style={{ paddingTop: "8px" }}>
                        <TextField
                            select
                            sx={{ width: "100%" }}
                            label={t("profitManagment:selectIncomeData")}
                            id="selectedEnterprise"
                            name="selectedEnterprise"
                            value={incomeData}
                            onChange={(id) => { setIncomeData(parseInt(id.target.value)) }}
                        //value={selectedEnterprise}
                        //onChange={(el) => setSelectedEnterprise(+el.target.value)}
                        >
                            {incomeDatas.map((el: any) =>
                                <MenuItem key={el.id} value={el.id}>
                                    {moment(el.startPeriodDate).format("DD.MM.yyyy")} - {moment(el.endPeriodDate).format("DD.MM.yyyy")}
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingTop: "8px" }}>
                        <CSVLink
                            filename={`${t("panelMenu:profitManagement").replace(" ", "_")}_${moment(ic?.startPeriodDate).format("DD.MM.yyyy") + "-" + moment(ic?.endPeriodDate).format("DD.MM.yyyy")}.csv`}
                            data={data.map((el: any) => [t(`profitManagment:${el.name}:title`), profit[el.name], t(`profitManagment:${el.name}:subtitle`)])}
                            className="btn btn-primary"
                        >
                            <Button>
                                CSV
                            </Button>
                        </CSVLink>
                    </Grid>
                    {/* <Grid item xs={12} md={4} style={{ paddingTop: "8px" }}>

                        <Button onClick={exportPDF}>
                            PDF
                        </Button>
                    </Grid> */}
                    {profit.vageZV != undefined ? <>
                        <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            <TableContainer >
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "bold" }}>{t("profitManagment:title")}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{t("profitManagment:value")}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{t("profitManagment:description")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((el: any) => (
                                            <>
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell sx={{ fontWeight: "500", fontSize: 16 }} component="th" scope="row">
                                                        {t(`profitManagment:${el.name}:title`)}
                                                    </TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">{profit[el.name]}</TableCell>
                                                    <TableCell>{t(`profitManagment:${el.name}:subtitle`)}</TableCell>
                                                </TableRow>
                                                {el.name == "recommendReserveCapital" ? <>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                            {t("profitManagment:prTitle")}
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </> : <></>}
                                                {el.name == "prCoefficient" ? <>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                                                            {t("profitManagment:zpfTitle")}
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </> : <></>}
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {data.map((el: any) => (
                            <>
                                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h6" style={{ fontWeight: "500" }} gutterBottom>
                                                {t(`profitManagment:${el.name}:title`)}
                                            </Typography>
                                            <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                                                {t("profitManagment:value") + ": " + profit[el.name]}
                                            </Typography>
                                            <Typography component="div" color="text.primary">
                                                {t(`profitManagment:${el.name}:subtitle`)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {el.name == "recommendReserveCapital" ? <>
                                    <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>{t("profitManagment:prTitle")}</Typography>
                                    </Grid>
                                </> : <></>}
                                {el.name == "prCoefficient" ? <>
                                    <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>{t("profitManagment:zpfTitle")}</Typography>
                                    </Grid>
                                </> : <></>}
                            </>
                        ))}

                    </>
                        :
                        <></>}
                </Grid>
            </Box >
        </>
    )
}
export default ProfitManagement;
