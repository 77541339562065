import { TextField, styled } from "@mui/material";
import { FormikConfig, FormikProps } from "formik";
import { useTranslation } from "react-i18next";

interface IFormikInput {
    formikVal: any,
    inputName: string,
    title: string,
    type?: string
}
export const StyledTextField = styled(TextField)({
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        backgroundColor: "#faf8f8",
        margin: 0,
        paddingLeft: 10
    },
});
const FormikInput = (props: IFormikInput) => {
    const { t } = useTranslation();

    return (
        <StyledTextField
            margin="normal"
            required
            fullWidth
            type={props.type}
            id={props.inputName}
            label={t(props.title)}
            name={props.inputName}
            autoComplete={props.inputName}
            variant="outlined"
            autoFocus
            value={props.formikVal.values[props.inputName]}
            onChange={props.formikVal.handleChange}
            error={props.formikVal.touched[props.inputName] && Boolean(props.formikVal.errors[props.inputName])}
            helperText={props.formikVal.touched[props.inputName] && props.formikVal.errors[props.inputName]}
        />
    )
}
export default FormikInput;