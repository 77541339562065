import createSagaMiddleware from 'redux-saga';
import {reducer} from './reducers/rootReducer';
import {handler as userSaga} from './reducers/user/saga';
import {handler as enterpricesSaga} from './reducers/enterprices/saga';
import {handler as incomeDataSaga} from './reducers/incomeData/saga';
import {handler as capitalManagmentSaga} from './reducers/capitalManagment/saga';
import {handler as LiquidityAnalysisSaga} from './reducers/liquidityAnalysis/saga';
import {handler as GraphAnalysisSaga} from './reducers/graphAnalysisFVFM/saga';
import {handler as GraphCoefUsingSaga} from './reducers/graphCoefUsing/saga';
import {handler as BankuptcyAnalysisSaga} from './reducers/bankuptcyAnalysis/saga';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
        devTools: process.env.NODE_ENV !== 'production'});

export type RootState = ReturnType<typeof store.getState>

sagaMiddleware.run(userSaga);
sagaMiddleware.run(enterpricesSaga);
sagaMiddleware.run(incomeDataSaga);
sagaMiddleware.run(capitalManagmentSaga);
sagaMiddleware.run(LiquidityAnalysisSaga);
sagaMiddleware.run(GraphAnalysisSaga);
sagaMiddleware.run(GraphCoefUsingSaga);
sagaMiddleware.run(BankuptcyAnalysisSaga);
