import { useTranslation } from "react-i18next";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

//import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthFooter, AuthTextField } from "../components";
import * as yup from 'yup';
import { FormikConfig, useFormik } from "formik";
import { useActions } from "../../../hook/useActions";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { Backdrop, CircularProgress, FormHelperText } from "@mui/material";
import { ILoginPost } from "../../../store/reducers/user/types";
import { Link, useNavigate } from "react-router-dom";
import GoogleAuth from "./google";


const theme = createTheme();
interface Values {
  email: string;
  password: string;
  rememberMe: boolean;
}
const LoginPage = () => {
  const { t } = useTranslation();
  const { loginRequest, loginDefault } = useActions();
  const { login, isLoggedIn } = useTypedSelector(state => state.user);
  const navigate = useNavigate();
  const { loading, error, success } = login;
  React.useEffect(() => {
    if (isLoggedIn) {
      navigate("/panel");
    }
  }, [])
  React.useEffect(() => {
    if (success) {
      loginDefault();
      navigate("/panel");
    }
  }, [success])
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("authTitles:emailErorTitleWrong") as string)
      .required(t("authTitles:emailErorTitleRequired") as string),
    password: yup
      .string()
      .min(8, t("authTitles:passwordTitleError") as string)
      .matches(/^(?=.*\d)(?=.*[A-Z])(?!.*\s).{8,24}$/, t("authTitles:passwordTitleError") as string)
      .required(t("authTitles:passwordTitleRequired") as string),
    rememberMe: yup
      .boolean()
      .required('Rememberme is required'),
  });
  const config: FormikConfig<Values> = {
    initialValues: {
      email: '',
      password: '',
      rememberMe: true
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      loginRequest(values as ILoginPost);
    },
  };
  const formik = useFormik<Values>(config);


  return (
    <ThemeProvider theme={theme}>
      <Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        sx={{ backgroundColor: "#FAF8F8", height: "100%", paddingY: "1px" }}
        component="main"
        maxWidth="sm"
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GoogleAuth />

          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", width: 70, height: 70 }}
            src={require("../../../assets/img/square_300x300.png")}
            variant="rounded"
          ></Avatar>
          <Typography component="h1" variant="h5">
            {t("login:title")}
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1, pl: 3, pr: 3 }}
          >
            <AuthTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("authTitles:emailTitle")}
              name="email"
              autoComplete="email"
              variant="outlined"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <AuthTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("authTitles:passwordTitle")}
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("login:rememberMeTitle")}
              value={formik.values.rememberMe}
              onChange={formik.handleChange}
            /> */}
            {error != null ? (
              <FormHelperText error>{t("login:loginError")}</FormHelperText>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: "20px", paddingTop: "5px" }}
            >
              {t("login:loginButton")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/" style={{ color: "#1565c0" }}>
                  {t("login:forgotPasswordTitle")}
                </Link>
              </Grid>
              <Grid item>
                <Link to="/register" style={{ color: "#1565c0" }}>
                  {t("login:registerTitle")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <AuthFooter sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );

};

export default LoginPage;
