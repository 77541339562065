
//console.log(process.env);
const REMOTE_HOST_NAME: string = (process.env.REACT_APP_BASE_URL as string);
const GOOGLE_CLIENT_ID: string = (process.env.REACT_APP_GOOGLE_CLIENT_ID as string);

const APP_ENV = {
   REMOTE_HOST_NAME: REMOTE_HOST_NAME,
   GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID
};

export { APP_ENV };