import {
  CreateTypes,
  IIncomeDataState,
  IncomeDataActions,
  GetListTypes,
  GetListRowCodesTypes,
  DeleteTypes,
  GetEditTypes,
  EditTypes,
  GetAssesmentTypes,
  GetProfitTypes,
  GetPersonnelTypes,
} from "./types";

const initialState: IIncomeDataState = {
  incomeDatas: [],
  codes: [],
  assesment: {},
  profit: {},
  personnel: {},
  getIncomeData: {
    loading: false,
    error: null,
    success: false,
  },
  getCodes: {
    loading: false,
    error: null,
    success: false,
  },
  createIncomeData: {
    loading: false,
    error: null,
    success: false,
  },
  getAssesments: {
    loading: false,
    error: null,
    success: false,
  },
  getProfit: {
    loading: false,
    error: null,
    success: false,
  },
  getPersonnel: {
    loading: false,
    error: null,
    success: false,
  },
  getEditIncomeData: {
    data: {},
    loading: false,
    error: null,
    success: false,
  },
  editIncomeData: {
    loading: false,
    error: null,
    success: false,
  },
  deleteIncomeData: {
    loading: false,
    error: null,
    success: false,
  },
};

const incomeDataReducer = (
  state: IIncomeDataState = initialState,
  action: IncomeDataActions
) => {
  switch (action.type) {
    //#region "Get all data"
    case GetListTypes.GET: {
      return {
        ...state,
        getIncomeData: {
          loading: true,
          error: null,
        },
      };
    }
    case GetListTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        incomeDatas: data,
        getIncomeData: {
          loading,
          success: true,
        },
      };
    }

    case GetListTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getIncomeData: {
          error,
          loading,
        },
      };
    }
    //#endregion

    //#region "Get Codes Data"
    case GetListRowCodesTypes.GET: {
      return {
        ...state,
        getKveds: {
          loading: true,
          error: null,
        },
      };
    }
    case GetListRowCodesTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        codes: data,
        getKveds: {
          loading,
          success: true,
        },
      };
    }

    case GetListRowCodesTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getKveds: {
          error,
          loading,
        },
      };
    }
    //#endregion

    //#region "Create Income Data"
    case CreateTypes.CREATE: {
      return {
        ...state,
        createIncomeData: {
          loading: true,
          error: null,
        },
      };
    }
    case CreateTypes.CREATE_SUCCESS: {
      const { loading } = action;
      return {
        ...state,
        createIncomeData: {
          loading,
          success: true,
        },
      };
    }

    case CreateTypes.CREATE_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        createIncomeData: {
          error,
          loading,
        },
      };
    }
    case CreateTypes.SET_DEFAULT: {
      return {
        ...state,
        createIncomeData: {
          error: null,
          loading: false,
          success: false,
        },
      };
    }
    //#endregion

    //#region "Delete Income Data"
    case DeleteTypes.DELETE: {
      return {
        ...state,
        deleteIncomeData: {
          loading: true,
          error: null,
        },
      };
    }
    case DeleteTypes.DELETE_SUCCES: {
      const { loading } = action;
      return {
        ...state,
        deleteIncomeData: {
          loading,
          success: true,
        },
      };
    }

    case DeleteTypes.DELETE_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        deleteIncomeData: {
          error,
          loading,
        },
      };
    }
    //#endregion

    //#region "Get edit Income Data"
    case GetEditTypes.GET: {
      return {
        ...state,
        getEditIncomeData: {
          loading: true,
          error: null,
        },
      };
    }
    case GetEditTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        getEditIncomeData: {
          data,
          loading,
          success: true,
        },
      };
    }

    case GetEditTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getEditIncomeData: {
          error,
          loading,
        },
      };
    }
    //#endregion

    //#region "Edit Income Data"
    case EditTypes.EDIT: {
      return {
        ...state,
        editIncomeData: {
          loading: true,
          error: null,
        },
      };
    }
    case EditTypes.EDIT_SUCCES: {
      const { loading } = action;
      return {
        ...state,
        editIncomeData: {
          loading,
          success: true,
        },
      };
    }

    case EditTypes.EDIT_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        editIncomeData: {
          error,
          loading,
        },
      };
    }
    case EditTypes.SET_DEFAULT: {
      return {
        ...state,
        editIncomeData: {
          error: null,
          loading: false,
          success: false,
        },
      };
    }
    //#endregion

    //#region "Get assesment Income Data"
    case GetAssesmentTypes.GET: {
      return {
        ...state,
        getAssesments: {
          loading: true,
          error: null,
        },
      };
    }
    case GetAssesmentTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        assesment: data,
        getAssesments: {
          loading,
          success: true,
        },
      };
    }

    case GetAssesmentTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getAssesments: {
          error,
          loading,
        },
      };
    }
    //#endregion

    //#region "Get profit Income Data"
    case GetProfitTypes.GET: {
      return {
        ...state,
        getProfit: {
          loading: true,
          error: null,
        },
      };
    }
    case GetProfitTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        profit: data,
        getProfit: {
          loading,
          success: true,
        },
      };
    }

    case GetProfitTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getProfit: {
          error,
          loading,
        },
      };
    }
    //#endregion

    //#region "Get profit Income Data"
    case GetPersonnelTypes.GET: {
      return {
        ...state,
        getPersonnel: {
          loading: true,
          error: null,
        },
      };
    }
    case GetPersonnelTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        personnel: data,
        getPersonnel: {
          loading,
          success: true,
        },
      };
    }

    case GetPersonnelTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getPersonnel: {
          error,
          loading,
        },
      };
    }
    //#endregion

    default:
      return state;
  }
};

export default incomeDataReducer;
