import {
	CreateEnterpriceActionsTypes,
	DeleteEnterpriceActionsTypes,
	EditEnterpriceActionsTypes,
	EnterpricesActionsTypes,
	IEnterpricesPost,
	KvedsActionsTypes
} from './types';

export const getEnterpricesRequest = () => (
	{
		type: EnterpricesActionsTypes.GET_ENTERPRICES
	}
);
export const getKvedsRequest = (section: string = "") => (
	{
		type: KvedsActionsTypes.GET_KVEDS,
		section
	}
);
export const createEnterpricesRequest = (model: IEnterpricesPost) => (
	{
		type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES,
		model
	}
);
export const createDefault = () => (
	{
		type: CreateEnterpriceActionsTypes.SET_DEFAULT,
	}
);
export const deleteEnterpriceRequest = (id:number) => (
	{
		type: DeleteEnterpriceActionsTypes.DELETE,
		id
	}
);
export const editEnterpricesRequest = (model: IEnterpricesPost) => (
	{
		type: EditEnterpriceActionsTypes.EDIT,
		model
	}
);
export const editDefault = () => (
	{
		type: EditEnterpriceActionsTypes.SET_DEFAULT,
	}
);