import { takeLatest, put, call, select } from "redux-saga/effects";
import { apiRequest } from "../../apiRequest";
import { URLs } from "../../urls";
import {
  IGetBankuptcyAnalysisResult,
  IResult,
  GetBankuptcyAnalysisTypes,
  GetBankuptcyAnalysisSucces,
  GetBankuptcyAnalysisError,
  IGetEditResult,
} from "./types";

function* getBankuptcyAnalysisRequest(action: any) {
  try {
    // API call
    const request: IGetEditResult = yield call(apiRequest, {
      endpoint: URLs.GET_BANKUPTCY_ANALYSIS_ENDPOINT + action.id,
      method: "GET",
    });
    if (request.success) {
      let putData: GetBankuptcyAnalysisSucces = {
        type: GetBankuptcyAnalysisTypes.GET_SUCCESS,
        data: request.data,
        loading: false,
      };
      yield put<GetBankuptcyAnalysisSucces>(putData);
    } else {
      let putData: GetBankuptcyAnalysisError = {
        type: GetBankuptcyAnalysisTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetBankuptcyAnalysisError>(putData);
    }
  } catch (e: any) {
    let putData: GetBankuptcyAnalysisError = {
      type: GetBankuptcyAnalysisTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetBankuptcyAnalysisError>(putData);
  }
}

export function* handler() {
  yield takeLatest(GetBankuptcyAnalysisTypes.GET, getBankuptcyAnalysisRequest);
}
