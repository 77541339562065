export enum LoginActionsTypes {
    POST_LOGIN = 'POST_LOGIN',
    POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS',
    POST_LOGIN_ERROR = 'POST_LOGIN_ERROR',
    SET_DEFAULT = 'SET_DEFAULT',
    CHECK = 'CHECK',
    CHECK_SUCCESS = 'CHECK_SUCCESS',
    LOGOUT = 'LOGOUT',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
}
export enum RegisterActionsTypes {
    POST_REGISTER = 'POST_REGISTER',
    POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS',
    POST_REGISTER_ERROR = 'POST_REGISTER_ERROR',
    SET_DEFAULT = 'SET_DEFAULT'
}



export interface ILoginPost {
    email: string,
    password: string
}
//---------------------Find login user google auth--------------------

export interface IGoogleLoginPost {
    provider: string,
    token: string,
    firstName: string,
    lastName: string,
}

export interface IFindLoginPost {
    provider: string,
    token: string
}

export enum GoogleActionsTypes {
    POST_FIND_LOGIN = 'POST_FIND_LOGIN',
    POST_FIND_LOGIN_NOTFOUND = 'POST_FIND_LOGIN_NOTFOUND',
    GOOGLE_LOGIN = 'GOOGLE_LOGIN',
}

export interface PostFindLoginRequest {
    type: GoogleActionsTypes.POST_FIND_LOGIN,
}
export interface PostFindLoginRequestNotFound {
    type: GoogleActionsTypes.POST_FIND_LOGIN_NOTFOUND,
    tokenId: string
}

export interface GoogleLoginRequest {
    type: GoogleActionsTypes.GOOGLE_LOGIN,
}

//---------------------------------------------------------------------
export interface IRegisterPost {
    firstName: string,
    lastName: string,
    email: string,
    password: string
}
export interface IUserState {
  isLoggedIn: boolean;
  user?: ITokenData;
  login: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  register: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
  findLogin: {
    token: string | null;
    success: boolean;
  };
}
export interface ILoginResult {
    data:{
        token: string,
        refreshToken: string,
    },
    success:boolean,
    error:string
}
export interface ITokenData {
    email:string,
    lastName:string,
    firstName:string,
    id:string,
}
export interface IResult {
    success:boolean,
    error:string
}

export interface PostRegisterRequest {
    type: RegisterActionsTypes.POST_REGISTER,
}
export interface PostRegisterRequestSucces {
    type: RegisterActionsTypes.POST_REGISTER_SUCCESS,
    success: boolean;
    loading: boolean;
}
export interface PostRegisterRequestError {
    type: RegisterActionsTypes.POST_REGISTER_ERROR,
    error:string,
    loading:boolean
}
export interface RegisterSetDefault {
    type: RegisterActionsTypes.SET_DEFAULT
}

export interface PostLoginRequest {
    type: LoginActionsTypes.POST_LOGIN,
}
export interface PostLoginRequestSucces {
    type: LoginActionsTypes.POST_LOGIN_SUCCESS,
    user: ITokenData;
    loading: boolean;
}

export interface PostLoginRequestError {
    type: LoginActionsTypes.POST_LOGIN_ERROR,
    error:string,
    loading:boolean
}
export interface LoginSetDefault {
    type: LoginActionsTypes.SET_DEFAULT
}
export interface LoginCheckDefault {
    type: LoginActionsTypes.CHECK_SUCCESS,
    user: ITokenData
}
export interface Logout {
    type: LoginActionsTypes.LOGOUT_SUCCESS
}

export type UserActions = PostLoginRequest | PostLoginRequestSucces | PostLoginRequestError | LoginSetDefault | LoginCheckDefault|
PostRegisterRequest | PostRegisterRequestSucces | PostRegisterRequestError | RegisterSetDefault | Logout | 
PostFindLoginRequest | PostFindLoginRequestNotFound | GoogleLoginRequest;