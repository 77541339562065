import { useTranslation } from "react-i18next";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

//import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthFooter, AuthTextField } from "../components";
import * as yup from 'yup';
import { FormikConfig, useFormik } from "formik";
import { useActions } from "../../../hook/useActions";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { Backdrop, CircularProgress, FormHelperText } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { loginDefault } from "../../../store/reducers/user/actions";
import jwtDecode from "jwt-decode";


const theme = createTheme();
interface Values {
  firstName: string;
  lastName: string;
}
export interface IGogoleTokenData {
  family_name:string,
  given_name:string,
}

const ConfirmGooglePage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  //console.log('token = ', token);
  const { googleLoginRequest } = useActions();
  const { login, isLoggedIn } = useTypedSelector(state => state.user);
  const navigate = useNavigate();
  const { loading, error, success } = login;

  React.useEffect(() => {
    const token = searchParams.get("token");
    if (token != null) {
      const data = jwtDecode<IGogoleTokenData>(token);
      setFieldValue("firstName", data.family_name);
      setFieldValue("lastName", data.given_name);
    }
  }, []);

  React.useEffect(() => {
    if (success) {
      loginDefault();
      navigate("/panel");
    }
  }, [success])
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t("authTitles:nameTitleRequired") as string),
    lastName: yup
      .string()
      .required(t("authTitles:lastNameTitleRequired") as string),
  });
  const config: FormikConfig<Values> = {
    initialValues: {
      firstName: '',
      lastName: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values) => {
      console.log("Send data Server", values);
      googleLoginRequest({
        ...values,
        provider: "Google",
        token: searchParams.get("token") as string,
      });
      //loginRequest(values as ILoginPost);
    },
  };
  const formik = useFormik<Values>(config);

  const {setFieldValue, values, handleChange, touched, errors} = formik;


  return (
    <ThemeProvider theme={theme}>
      <Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        sx={{ backgroundColor: "#FAF8F8", height: "100%", paddingY: "1px" }}
        component="main"
        maxWidth="sm"
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", width: 70, height: 70 }}
            src={require("../../../assets/img/square_300x300.png")}
            variant="rounded"
          ></Avatar>
          <Typography component="h1" variant="h5">
            {t("confirmGoogle:title")}
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1, pl: 3, pr: 3 }}
          >
            <AuthTextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label={t("authTitles:nameTitle")}
              name="firstName"
              autoComplete="firstName"
              variant="outlined"
              autoFocus
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <AuthTextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={t("authTitles:lastNameTitle")}
              name="lastName"
              autoComplete="lastName"
              variant="outlined"
              autoFocus
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
            {error != null ? <FormHelperText error>{t("register:registerError")}</FormHelperText> : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: "20px", paddingTop: "5px" }}
            >
              {t("register:registerButton")}
            </Button>
          </Box>
        </Box>
        <AuthFooter sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );

};

export default ConfirmGooglePage;
