import {
  ICoefUsingState,
  CoefUsingActions,
  GetCoefUsingTypes,
} from "./types";

const initialState: ICoefUsingState = {
  data: [],
  getData: {
    loading: false,
    error: null,
    success: false,
  },
};

const coefUsingReducer = (
  state: ICoefUsingState = initialState,
  action: CoefUsingActions
) => {
  switch (action.type) {
    //#region "Get capital Income Data"
    case GetCoefUsingTypes.GET: {
      return {
        ...state,
        getData: {
          loading: true,
          error: null,
        },
      };
    }
    case GetCoefUsingTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        data: data,
        getData: {
          loading,
          success: true,
        },
      };
    }

    case GetCoefUsingTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getData: {
          error,
          loading,
        },
      };
    }
    //#endregion

    default:
      return state;
  }
};

export default coefUsingReducer;
