
export enum GetCapitalTypes {
  GET = "GET_CAPITAL_MANAGMENT",
  GET_SUCCESS = "GET_CAPITAL_MANAGMENT_SUCCESS",
  GET_ERROR = "GET_CAPITAL_MANAGMENT_ERROR",
}

export interface ICapitalManagmentState {
  data: {};
  getData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
}
export interface IGetCapitalManagmentResult {
  data: [];
  success: boolean;
  error: string;
}
export interface IGetEditResult {
  data: {};
  success: boolean;
  error: string;
}
export interface IResult {
  success: boolean;
  error: string;
}

export interface GetCapitalRequest {
  type: GetCapitalTypes.GET;
}
export interface GetCapitalSucces {
  type: GetCapitalTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetCapitalError {
  type: GetCapitalTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export type CapitalManagmentActions =
  | GetCapitalRequest
  | GetCapitalSucces
  | GetCapitalError;
