import {
	LoginActionsTypes,
    ILoginPost,
	IRegisterPost,
	RegisterActionsTypes,
	IFindLoginPost,
	GoogleActionsTypes,
	IGoogleLoginPost
} from './types';

export const loginRequest = (model: ILoginPost) => (
	{
		type: LoginActionsTypes.POST_LOGIN,
		model		
	}
);
export const registerRequest = (model: IRegisterPost) => (
	{
		type: RegisterActionsTypes.POST_REGISTER,
		model		
	}
);
export const registerDefault = () => (
	{
		type: RegisterActionsTypes.SET_DEFAULT
	}
);

export const loginDefault = () => (
	{
		type: LoginActionsTypes.SET_DEFAULT
	}
);

export const checkLoggedIn = () => (
	{
		type: LoginActionsTypes.CHECK
	}
);
export const logout = () => (
	{
		type: LoginActionsTypes.LOGOUT
	}
);

export const findLoginRequest = (model: IFindLoginPost) => ({
  type: GoogleActionsTypes.POST_FIND_LOGIN,
  model,
});

export const googleLoginRequest = (model: IGoogleLoginPost) => ({
	type: GoogleActionsTypes.GOOGLE_LOGIN,
	model,
  });