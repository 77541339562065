import { Outlet, useNavigate } from "react-router-dom";
import PanelDrawer from "./PanelDrawer";
import { Box, Toolbar } from "@mui/material";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { useEffect } from "react";

const PanelLayout = () => {
    const { isLoggedIn } = useTypedSelector(state => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        console.log(isLoggedIn);
        if (!isLoggedIn) {
            navigate("/");
        }
    }, [])
    return (
        <>
            <Box sx={{ display: 'flex' }}>

                <PanelDrawer />
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - 300px)` } }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>

            </Box>
        </>
    )
}

export default PanelLayout;