import { useTranslation } from "react-i18next";
import { useActions } from "../../../hook/useActions";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Grid, MenuItem, TextField, Typography } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const GraphUsing = () => {
    const { t } = useTranslation();
    const { getCoefUsingRequest } = useActions();
    const { data } = useTypedSelector<any>(
        (state) => state.graphCoefUsing
    );
    // useEffect(() => {
    //     getCoefUsingRequest();
    // }, [])
    console.log(data);
    const labels = data?.map((el: any) => (moment(el.startPeriodDate).format("DD.MM.yyyy")) + "-" + moment(el.endPeriodDate).format("DD.MM.yyyy"));
    const dataСр = {
        labels: labels,
        datasets: [{
            label: t("assetManagment:kzTitle"),
            data: data?.map((el: any) => el.kz),
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }, {
            label: t("assetManagment:kpTitle"),
            data: data?.map((el: any) => el.kp),
            fill: false,
            borderColor: '#9bbb59',
            tension: 0.1
        }, {
            label: t("assetManagment:koTitle"),
            data: data?.map((el: any) => el.ko),
            fill: false,
            borderColor: '#c0504d',
            tension: 0.1
        }]
    };
    const config = {
        type: 'line',
        data: dataСр,
    };
    const { getEnterpricesRequest, deleteEnterpriceRequest } = useActions();
    const { enterprices, deleteEnterprice } = useTypedSelector<any>(state => state.enterprices);
    const {success}= deleteEnterprice;
    const [enterpriceId,setEnterpriceId] = useState(0);
    useEffect(() => {
        getEnterpricesRequest();
    }, []);
    useEffect(() => {
        getCoefUsingRequest(enterpriceId);
    }, [enterpriceId]);
    return (
        <>
            <Grid item xs={12} md={4} style={{ paddingTop: "8px" }}>
                <TextField
                    select
                    sx={{ width: "100%" }}
                    label={"Оберіть підприємство"}
                    id="selectedEnterprise"
                    name="selectedEnterprise"
                    value={enterpriceId}
                    onChange={(id) => {
                        setEnterpriceId(parseInt(id.target.value));
                    }}
                //value={selectedEnterprise}
                //onChange={(el) => setSelectedEnterprise(+el.target.value)}
                >
                    {enterprices.map((el: any) => (
                        <MenuItem key={el.id} value={el.id}>
                            {el.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Line options={{}} data={dataСр} />
            <Grid item style={{ marginTop: "15px" }}>
                <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>{t("assetManagment:kzTitle")}</Typography>
                <Typography variant="h6">Висновок: {data?.map((el: any) => el.fm)[1] == 0 ? "підприємство не має основних засобів" : data?.map((el: any) => el.fm)[1] > 0.60 ? "Підприємству потрібно проаналізувати потреби в заміні основних фондів і спланувати бюджет щодо цього" : " підприємство помірно оновлює основні фонди його політика вірна і спланована."}</Typography>
            </Grid>
            {/* <Grid item style={{marginTop:"15px"}}>                            
                <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>{t("assetManagment:fvTitle")}</Typography>
                <Typography  variant="h6">Висновок: {data?.map((el: any) => el.fm)[0]>data?.map((el: any) => el.fm)[1] ? "Показник зменшився в порівнянні з минулим роком, це свідчить про зменшення ефективності використання основних засобів. Потрібно дослідити чи основні фонди підприємства використовуються на повну потужність":"Показник зростає в порівнянні з минулим роком, це свідчить про підвищеня ефективності використання основних засобів"}</Typography>
            </Grid> */}
        </>
    )
}
export default GraphUsing;