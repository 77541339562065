import {
  ICapitalManagmentState,
  CapitalManagmentActions,
  GetCapitalTypes,
} from "./types";

const initialState: ICapitalManagmentState = {
  data: {},
  getData: {
    loading: false,
    error: null,
    success: false,
  },
};

const capitalManagmentReducer = (
  state: ICapitalManagmentState = initialState,
  action: CapitalManagmentActions
) => {
  switch (action.type) {
    //#region "Get capital Income Data"
    case GetCapitalTypes.GET: {
      return {
        ...state,
        getData: {
          loading: true,
          error: null,
        },
      };
    }
    case GetCapitalTypes.GET_SUCCESS: {
      const { loading, data } = action;
      return {
        ...state,
        data: data,
        getData: {
          loading,
          success: true,
        },
      };
    }

    case GetCapitalTypes.GET_ERROR: {
      const { error, loading } = action;
      return {
        ...state,
        getData: {
          error,
          loading,
        },
      };
    }
    //#endregion

    default:
      return state;
  }
};

export default capitalManagmentReducer;
