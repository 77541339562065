import { useTranslation } from "react-i18next";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthFooter, AuthTextField } from "../components";
import * as yup from 'yup';
import { FormikConfig, useFormik } from "formik";
import { useActions } from "../../../hook/useActions";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { Backdrop, CircularProgress, FormHelperText } from "@mui/material";
import { ILoginPost, IRegisterPost } from "../../../store/reducers/user/types";
import { useNavigate } from "react-router";


const theme = createTheme();
interface Values {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  rememberMe: boolean;
}
const RegisterPage = () => {
  const { t } = useTranslation();
  const { registerRequest, registerDefault } = useActions();
  const navigate = useNavigate();
  const { register } = useTypedSelector(state => state.user);
  const {loading, error, success} = register;
  console.log("success",success);
  React.useEffect(() => {
    if (success) {
      registerDefault();
      navigate("/login");
    }
  }, [success]);
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t("authTitles:nameTitleRequired") as string),
    lastName: yup
      .string()
      .required(t("authTitles:lastNameTitleRequired") as string),
    email: yup
      .string()
      .email(t("authTitles:emailErorTitleWrong") as string)
      .required(t("authTitles:emailErorTitleRequired") as string),
    password: yup
      .string()
      .min(8, t("authTitles:passwordTitleError") as string)
      .matches(/^(?=.*\d)(?=.*[A-Z])(?!.*\s).{8,24}$/, t("authTitles:passwordTitleError") as string)
      .required(t("authTitles:passwordTitleRequired") as string),
  });
  const config: FormikConfig<Values> = {
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      rememberMe: true
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      registerRequest(values as IRegisterPost);
    },
  };
  const formik = useFormik<Values>(config);


  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 11 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ backgroundColor: "#FAF8F8", height: "100%", paddingY: "30px" }} component="main" maxWidth="sm" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 70, height: 70 }} src={require("../../../assets/img/square_300x300.png")}
            variant="rounded">
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("register:title")}
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, pl: 3, pr: 3 }}>
            <AuthTextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label={t("authTitles:nameTitle")}
              name="firstName"
              autoComplete="firstName"
              variant="outlined"
              autoFocus
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <AuthTextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={t("authTitles:lastNameTitle")}
              name="lastName"
              autoComplete="lastName"
              variant="outlined"
              autoFocus
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <AuthTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("authTitles:emailTitle")}
              name="email"
              autoComplete="email"
              variant="outlined"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <AuthTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("authTitles:passwordTitle")}
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("register:rememberMeTitle")}
              value={formik.values.rememberMe}
              onChange={formik.handleChange}
            /> */}
            {error != null ? <FormHelperText error>{t("register:registerError")}</FormHelperText> : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: "20px", paddingTop: "5px" }}
            >
              {t("register:registerButton")}
            </Button>
          </Box>
        </Box>
        <AuthFooter sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );

};

export default RegisterPage;
