import navbar from "./navbar.json";
import login from "./login.json";
import authTitles from "./authTitles.json";
import register from "./register.json";
import confirmGoogle from "./confirmGoogle.json";
import panelMenu from "./panelMenu.json";
import enterprices from "./enterprices.json";
import incomeData from "./incomeData.json";

export default {
    navbar,
    login,
    authTitles,
    register,
    confirmGoogle,
    panelMenu,
    enterprices,
    incomeData
}