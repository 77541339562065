import { takeLatest, put, call, select } from "redux-saga/effects";
import { apiRequest } from "../../apiRequest";
import { URLs } from "../../urls";
import {
  LoginActionsTypes,
  ILoginResult,
  PostLoginRequestSucces,
  PostLoginRequestError,
  RegisterActionsTypes,
  PostRegisterRequestError,
  PostRegisterRequestSucces,
  LoginCheckDefault,
  IResult,
  ITokenData,
  Logout,
  GoogleActionsTypes,
  PostFindLoginRequestNotFound,
} from "./types";
import { isGeneratorFunction } from "util/types";
import jwtDecode from "jwt-decode";
import { string } from "yup";

function* sendLoginRequest(action: any) {
  try {
    // API call
    const loginRequest: ILoginResult = yield call(apiRequest, {
      endpoint: URLs.LOGIN_ENDPOINT,
      method: "POST",
      body: {
        email: action.model.email,
        password: action.model.password,
      },
    });
    if (loginRequest.error === null) {
      localStorage.accessToken = loginRequest.data.token;
      localStorage.refreshToken = loginRequest.data.refreshToken;
      let putData: PostLoginRequestSucces = {
        type: LoginActionsTypes.POST_LOGIN_SUCCESS,
        user: jwtDecode<ITokenData>(loginRequest.data.token),
        loading: false,
      };
      yield put<PostLoginRequestSucces>(putData);
    } else if (loginRequest.error != null) {
      let putData: PostLoginRequestError = {
        type: LoginActionsTypes.POST_LOGIN_ERROR,
        error: loginRequest.error,
        loading: false,
      };
      yield put<PostLoginRequestError>(putData);
    }
  } catch (e: any) {
    let putData: PostLoginRequestError = {
      type: LoginActionsTypes.POST_LOGIN_ERROR,
      error: e,
      loading: false,
    };
    yield put<PostLoginRequestError>(putData);
  }
}

function* sendRegisterRequest(action: any) {
  try {
    // API call
    const loginRequest: IResult = yield call(apiRequest, {
      endpoint: URLs.REGISTER_ENDPOINT,
      method: "POST",
      body: {
        email: action.model.email,
        password: action.model.password,
        firstName: action.model.firstName,
        lastName: action.model.lastName,
      },
    });
    console.log("success1", loginRequest.success);

    if (loginRequest.success) {
      let putData: PostRegisterRequestSucces = {
        type: RegisterActionsTypes.POST_REGISTER_SUCCESS,
        success: true,
        loading: false,
      };
      yield put<PostRegisterRequestSucces>(putData);
    } else {
      let putData: PostRegisterRequestError = {
        type: RegisterActionsTypes.POST_REGISTER_ERROR,
        error: loginRequest.error,
        loading: false,
      };
      yield put<PostRegisterRequestError>(putData);
    }
  } catch (e: any) {
    console.log("e", e);

    let putData: PostRegisterRequestError = {
      type: RegisterActionsTypes.POST_REGISTER_ERROR,
      error: e,
      loading: false,
    };
    yield put<PostRegisterRequestError>(putData);
  }
}
function* checkLoggedIn(action: any) {
  console.log("localStorage.accessToken", localStorage.accessToken);
  if (localStorage.accessToken != undefined) {
    let putData: LoginCheckDefault = {
      type: LoginActionsTypes.CHECK_SUCCESS,
      user: jwtDecode<ITokenData>(localStorage.accessToken),
    };
    yield put<LoginCheckDefault>(putData);
  }
}
export function* logout() {
  console.log("logout")
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  let putData: Logout = {
    type: LoginActionsTypes.LOGOUT_SUCCESS,
  };
  yield put<Logout>(putData);
}

function* sendFindLoginRequest(action: any) {
  try {
    // API call
    const loginRequest: ILoginResult = yield call(apiRequest, {
      endpoint: URLs.FIND_LOGIN_ENDPOINT,
      method: "POST",
      body: {
        provider: action.model.provider,
        token: action.model.token,
      },
    });
    if (loginRequest.error === null) {
      localStorage.accessToken = loginRequest.data.token;
      localStorage.refreshToken = loginRequest.data.refreshToken;
      let putData: PostLoginRequestSucces = {
        type: LoginActionsTypes.POST_LOGIN_SUCCESS,
        user: jwtDecode<ITokenData>(loginRequest.data.token),
        loading: false,
      };
      yield put<PostLoginRequestSucces>(putData);
    } else if (loginRequest.error != null) {
      let putData: PostFindLoginRequestNotFound = {
        type: GoogleActionsTypes.POST_FIND_LOGIN_NOTFOUND,
        tokenId: action.model.token
      };
      yield put<PostFindLoginRequestNotFound>(putData);
    }
  } catch (e: any) {
    let putData: PostFindLoginRequestNotFound = {
      type: GoogleActionsTypes.POST_FIND_LOGIN_NOTFOUND,
      tokenId: action.model.token
    };
    yield put<PostFindLoginRequestNotFound>(putData);
  }
}

function* googleLoginRequest(action: any) {
  try {
    // API call
    const loginRequest: ILoginResult = yield call(apiRequest, {
      endpoint: URLs.GOOGLE_LOGIN_ENDPOINT,
      method: "POST",
      body: {
        provider: action.model.provider,
        token: action.model.token,
        firstName: action.model.firstName,
        lastName: action.model.lastName
      },
    });
    if (loginRequest.error === null) {
      localStorage.accessToken = loginRequest.data.token;
      localStorage.refreshToken = loginRequest.data.refreshToken;
      let putData: PostLoginRequestSucces = {
        type: LoginActionsTypes.POST_LOGIN_SUCCESS,
        user: jwtDecode<ITokenData>(loginRequest.data.token),
        loading: false,
      };
      yield put<PostLoginRequestSucces>(putData);
    } else if (loginRequest.error != null) {
      let putData: PostFindLoginRequestNotFound = {
        type: GoogleActionsTypes.POST_FIND_LOGIN_NOTFOUND,
        tokenId: action.model.token
      };
      yield put<PostFindLoginRequestNotFound>(putData);
    }
  } catch (e: any) {
    let putData: PostFindLoginRequestNotFound = {
      type: GoogleActionsTypes.POST_FIND_LOGIN_NOTFOUND,
      tokenId: action.model.token
    };
    yield put<PostFindLoginRequestNotFound>(putData);
  }
}

export function* handler() {
  yield takeLatest(LoginActionsTypes.POST_LOGIN, sendLoginRequest);
  yield takeLatest(RegisterActionsTypes.POST_REGISTER, sendRegisterRequest);
  yield takeLatest(LoginActionsTypes.CHECK, checkLoggedIn);
  yield takeLatest(LoginActionsTypes.LOGOUT, logout);
  yield takeLatest(GoogleActionsTypes.POST_FIND_LOGIN, sendFindLoginRequest);
  yield takeLatest(GoogleActionsTypes.GOOGLE_LOGIN, googleLoginRequest);

}
