import { takeLatest, put, call, select } from "redux-saga/effects";
import { apiRequest } from "../../apiRequest";
import { URLs } from "../../urls";
import {
  EnterpricesActionsTypes, IGetEnterpriceResult,
  EnterpricesRequestSucces,
  EnterpricesRequestError,KvedsActionsTypes, GetKvedRequestSucces, GetKvedRequestError, IResult, CreateEnterpricesRequestSucces, CreateEnterpriceActionsTypes, CreateEnterpricesRequestRequestError, EditEnterpriceActionsTypes, EditEnterpricesRequestSucces, EditEnterpricesRequesError, DeleteEnterpriceActionsTypes, DeleteEnterpricesRequesError, DeleteEnterpricesRequestSucces
} from "./types";

function* getEnterpricesRequest(action: any) {
  try {
    // API call
    const request: IGetEnterpriceResult = yield call(apiRequest, {
      endpoint: URLs.GET_ENTERPRICES_ENDPOINT,
      method: "GET"
    });
    if (request.success) {      
      let putData: EnterpricesRequestSucces = {
        type: EnterpricesActionsTypes.GET_ENTERPRICES_SUCCESS,
        enterprices:  request.data,
        loading: false,
      };
      yield put<EnterpricesRequestSucces>(putData);
    } else {
      let putData: EnterpricesRequestError = {
        type: EnterpricesActionsTypes.GET_ENTERPRICES_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<EnterpricesRequestError>(putData);
    }
  } catch (e: any) {
    let putData: EnterpricesRequestError = {
      type: EnterpricesActionsTypes.GET_ENTERPRICES_ERROR,
      error: e,
      loading: false,
    };
    yield put<EnterpricesRequestError>(putData);
  }
}

function* getKvedsRequest(action: any) {
  try {
    // API call
    const request: IGetEnterpriceResult = yield call(apiRequest, {
      endpoint: URLs.GET_KVEDS_ENDPOINT+action.section,
      method: "GET"
    });
    if (request.success) {      
      let putData: GetKvedRequestSucces = {
        type: KvedsActionsTypes.GET_KVEDS_SUCCESS,
        kveds: request.data,
        loading: false,
      };
      yield put<GetKvedRequestSucces>(putData);
    } else {
      let putData: GetKvedRequestError = {
        type: KvedsActionsTypes.GET_KVEDS_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetKvedRequestError>(putData);
    }
  } catch (e: any) {
    let putData: GetKvedRequestError = {
      type: KvedsActionsTypes.GET_KVEDS_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetKvedRequestError>(putData);
  }
}

function* createEnterpricesRequest(action: any) {
  try {
    console.log("HER");
    // API call
    const request: IResult = yield call(apiRequest, {
      endpoint: URLs.CREATE_KVED_ENDPOINT,
      method: "POST",
      body: action.model
    });
    if (request.success) {      
      let putData: CreateEnterpricesRequestSucces = {
        type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_SUCCESS,
        loading: false,
      };
      yield put<CreateEnterpricesRequestSucces>(putData);
    } else {
      let putData: CreateEnterpricesRequestRequestError = {
        type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<CreateEnterpricesRequestRequestError>(putData);
    }
  } catch (e: any) {
    let putData: CreateEnterpricesRequestRequestError = {
      type: CreateEnterpriceActionsTypes.CREATE_ENTERPRICES_ERROR,
      error: e,
      loading: false,
    };
    yield put<CreateEnterpricesRequestRequestError>(putData);
  }
}
function* editEnterpricesRequest(action: any) {
  try {
    // API call
    const request: IResult = yield call(apiRequest, {
      endpoint: URLs.EDIT_ENTERPRICE_ENDPOINT,
      method: "PUT",
      body: action.model
    });
    if (request.success) {      
      let putData: EditEnterpricesRequestSucces = {
        type: EditEnterpriceActionsTypes.EDIT_SUCCES,
        loading: false,
      };
      yield put<EditEnterpricesRequestSucces>(putData);
    } else {
      let putData: EditEnterpricesRequesError = {
        type: EditEnterpriceActionsTypes.EDIT_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<EditEnterpricesRequesError>(putData);
    }
  } catch (e: any) {
    let putData: EditEnterpricesRequesError = {
      type: EditEnterpriceActionsTypes.EDIT_ERROR,
      error: e,
      loading: false,
    };
    yield put<EditEnterpricesRequesError>(putData);
  }
}
function* deleteEnterpricesRequest(action: any) {
  try {
    // API call
    const request: IResult = yield call(apiRequest, {
      endpoint: URLs.DELETE_ENTERPRICE_ENDPOINT+action.id,
      method: "DELETE",
    });
    if (request.success) {      
      let putData: DeleteEnterpricesRequestSucces = {
        type: DeleteEnterpriceActionsTypes.DELETE_SUCCES,
        loading: false,
      };
      yield put<DeleteEnterpricesRequestSucces>(putData);
    } else {
      let putData: DeleteEnterpricesRequesError = {
        type: DeleteEnterpriceActionsTypes.DELETE_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<DeleteEnterpricesRequesError>(putData);
    }
  } catch (e: any) {
    let putData: DeleteEnterpricesRequesError = {
      type: DeleteEnterpriceActionsTypes.DELETE_ERROR,
      error: e,
      loading: false,
    };
    yield put<DeleteEnterpricesRequesError>(putData);
  }
}
export function* handler() {
  yield takeLatest(EnterpricesActionsTypes.GET_ENTERPRICES, getEnterpricesRequest);
  yield takeLatest(KvedsActionsTypes.GET_KVEDS, getKvedsRequest);
  yield takeLatest(CreateEnterpriceActionsTypes.CREATE_ENTERPRICES, createEnterpricesRequest);
  yield takeLatest(EditEnterpriceActionsTypes.EDIT, editEnterpricesRequest);
  yield takeLatest(DeleteEnterpriceActionsTypes.DELETE, deleteEnterpricesRequest);
}
