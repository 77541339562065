import { useEffect } from "react";
import Grid from "@mui/material/Grid/Grid";
import { Box, Button, Card, TextField, styled, FormHelperText, Backdrop } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { FormikConfig, FormikProps, useFormik } from "formik";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router";
import TreeSelect, {
    FreeSoloNode,
    DefaultOption,
    getDefaultOptionProps,
} from "mui-tree-select";
import { useActions } from "../../../../hook/useActions";
import { useTypedSelector } from "../../../../hook/useTypedSelector";
import { CircularProgress } from "@mui/material";
import { IEnterpricesPost } from "../../../../store/reducers/enterprices/types";
import { useDispatch } from "react-redux";
import FormikInput from "../../../../components/FormikInput";

const EntTextField = styled(TextField)({
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        backgroundColor: "#faf8f8",
        margin: 0,
        paddingLeft: 10
    },
});
interface Values {
    name: string;
    registrationNumber: string;
    cityName: string;
    adressName: string;
    kvedGroups: Array<string>;
}
const Enterprices = () => {
    //const { enterprices } = useTypedSelector<any>(state => state.enterprices);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getKvedsRequest,createEnterpricesRequest, createDefault } = useActions();
    const { kveds, createEnterprice } = useTypedSelector<any>(state => state.enterprices);
    const { loading, success, error } = createEnterprice;
    let kv:any = JSON.parse(JSON.stringify(kveds));
    if(kv.length > 1){
        kv.map((el: any) => {
            el.toString = () => (
                el.section
            )
            el.subKveds.map((it: any) => {
                it.toString = () => (
                    it.section
                );
                it.subKveds.map((it2: any) => {
                    it2.toString = () => (
                        it2.section
                    );
                    return it2;
                })
                return it;
            })
            return el;
        });
    }
    useEffect(() => {
        getKvedsRequest();
    }, []);
    useEffect(() => {
        if(success){
            createDefault();
            navigate("/panel/enterprices");
        }
    }, [success]);
    const validationSchema = yup.object({
        name: yup
            .string()
            .required(t("enterprices:nameRequired") as string),
        cityName: yup
            .string()
            .required(t("enterprices:cityRequired") as string),
        registrationNumber: yup
            .string()
            .required(t("enterprices:registrationNumberRequired") as string),
        adressName: yup
            .string()
            .required(t("enterprices:addressRequired") as string),
        kvedGroups: yup
            .array()
            .min(1, t("enterprices:kvedRequired") as string),
    });
    const config: FormikConfig<Values> = {
        initialValues: {
            name: '',
            cityName: '',
            adressName: '',
            registrationNumber: "",
            kvedGroups: []
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            let model: IEnterpricesPost = JSON.parse(JSON.stringify(values));;
            model.cityRef = model.cityName;
            model.adressRef = model.adressName;
            model.kvedGroups = model.kvedGroups.map((x: any)=>x.section) as []
            
            console.log("model", model);
            createEnterpricesRequest(model);
        },
    };
    const syncOrAsync = function (value: any, returnAsync: any) {
        if (returnAsync) {
            return new Promise((resolve) =>
                setTimeout(() => resolve(value), Math.random() * 500)
            );
        }
        return value;
    };
    const formik = useFormik<Values>(config);
    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card elevation={0} sx={{ backgroundColor: "#FAF8F8", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingY: 1 }}>
                        <Button color="secondary" onClick={() => { navigate("/panel/enterprices") }} startIcon={<ArrowBackIcon />}>
                            {t("enterprices:allEnterprices")}
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} >
                    <Card elevation={0} sx={{ backgroundColor: "#FAF8F8", paddingY: 1, paddingX: 3 }}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, pl: 3, pr: 3 }}>
                            <FormikInput inputName="name" title="enterprices:nameTitle" formikVal={formik}/>
                            <FormikInput inputName="registrationNumber" title="enterprices:registrationNumberTitle" formikVal={formik}/>
                            <FormikInput inputName="cityName" title="enterprices:cityTitle" formikVal={formik}/>
                            <FormikInput inputName="adressName" title="enterprices:addressTitle" formikVal={formik}/>                            
                            {[...kv].length>1 ? 
                            <TreeSelect
                                addFreeSoloText={t("enterprices:selectedKvedsTitle") as string}
                                getChildren={(item) => {
                                    let node = item as any
                                    return node ? node.subKveds.length == 0 ? null : node.subKveds : kv
                                }
                                }
                                getParent={(item) => { let node = item as any; return kv[kv.indexOf(kv.find((x: any) => x.section == node.section)) - 1] }}
                                multiple
                                renderInput={(params) => (
                                    <EntTextField
                                        {...params}
                                        margin="normal"
                                        label={t("enterprices:selectedKvedsTitle")}
                                        error={formik.touched.kvedGroups && Boolean(formik.errors.kvedGroups)}
                                        helperText={formik.touched.kvedGroups && formik.errors.kvedGroups}
                                    />
                                )}
                                renderOption={(...args) => (
                                    <DefaultOption
                                        {...((props, node) => {
                                            let n = node as any;
                                            return {
                                                ...props,
                                                ListItemTextProps: {
                                                    ...props.ListItemTextProps,
                                                    secondary: n.title,
                                                    //primary: n.section,
                                                    title: n.section
                                                },

                                            };
                                        })(getDefaultOptionProps(...args), args[1])}
                                    />
                                )}
                                value={formik.values.kvedGroups}
                                onChange={(_, value) => { console.log("val", value); formik.setFieldValue("kvedGroups", value) }}

                            />: null}
                            {error != null ? <FormHelperText error>{t("register:registerError")}</FormHelperText> : null}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 5, mb: 2, borderRadius: "20px", paddingTop: "5px" }}
                            >
                                {t("enterprices:createButton")}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Enterprices;