
export enum GetBankuptcyAnalysisTypes {
  GET = "GET_BANKUPTCY_ANALYSIS",
  GET_SUCCESS = "GET_BANKUPTCY_ANALYSIS_SUCCESS",
  GET_ERROR = "GET_BANKUPTCY_ANALYSIS_ERROR",
}

export interface IBankuptcyAnalysisState {
  data: {};
  getData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
}
export interface IGetBankuptcyAnalysisResult {
  data: [];
  success: boolean;
  error: string;
}
export interface IGetEditResult {
  data: {};
  success: boolean;
  error: string;
}
export interface IResult {
  success: boolean;
  error: string;
}

export interface GetBankuptcyAnalysisRequest {
  type: GetBankuptcyAnalysisTypes.GET;
}
export interface GetBankuptcyAnalysisSucces {
  type: GetBankuptcyAnalysisTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetBankuptcyAnalysisError {
  type: GetBankuptcyAnalysisTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export type BankuptcyAnalysisActions =
  | GetBankuptcyAnalysisRequest
  | GetBankuptcyAnalysisSucces
  | GetBankuptcyAnalysisError;
