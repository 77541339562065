import * as UserActionCreators from "../reducers/user/actions";
import * as EnterpricesActionCreators from "../reducers/enterprices/actions";
import * as IncomeDataActionCreators from "../reducers/incomeData/actions";
import * as capitalManagment from "../reducers/capitalManagment/actions";
import * as LiquidityAnalysis from "../reducers/liquidityAnalysis/actions";
import * as GraphAnalysis from "../reducers/graphAnalysisFVFM/actions";
import * as GraphCoef from "../reducers/graphCoefUsing/actions";
import * as BankuptcyAnalysis from "../reducers/bankuptcyAnalysis/actions";

const actions = {
    ...UserActionCreators,
    ...EnterpricesActionCreators,
    ...IncomeDataActionCreators,
    ...capitalManagment,
    ...LiquidityAnalysis,
    ...GraphAnalysis,
    ...BankuptcyAnalysis,
    ...GraphCoef,
}

export default actions;
