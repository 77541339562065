import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const AuthTextField = styled(TextField) ({
    backgroundColor: "#fff",
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
      backgroundColor: "#faf8f8",
      margin:0,
      paddingLeft: 10
    },
});
export default AuthTextField;