import {
  CreateTypes,
  IIncomeDataPost,
  GetListTypes,
  GetListRowCodesTypes,
  DeleteTypes,
  GetEditTypes,
  EditTypes,
  GetAssesmentTypes,
  GetProfitTypes,
  GetPersonnelTypes,
} from "./types";

export const getIncomeDataRequest = () => ({
  type: GetListTypes.GET,
});
export const getCodesRequest = () => ({
  type: GetListRowCodesTypes.GET,
});
export const createIncomeDataRequest = (model: IIncomeDataPost) => ({
  type: CreateTypes.CREATE,
  model,
});
export const createIncomeDataDefault = () => ({
  type: CreateTypes.SET_DEFAULT,
});

export const editIncomeDataRequest = (id: number, model: IIncomeDataPost) => ({
  type: EditTypes.EDIT,
  model,
  id,
});
export const getEditIncomeDataDefault = (id: number) => ({
  type: GetEditTypes.GET,
  id,
});
export const getAssesments = (id: number) => ({
  type: GetAssesmentTypes.GET,
  id,
});
export const getProfitRequest = (id: number) => ({
  type: GetProfitTypes.GET,
  id,
});

export const deleteIncomeDataRequest = (id: number) => ({
  type: DeleteTypes.DELETE,
  id,
});

export const getPersonnelRequest = (id: number) => ({
  type: GetPersonnelTypes.GET,
  id,
});
