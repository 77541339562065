import { Backdrop, Box, Button, Card, CircularProgress, FormHelperText, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTypedSelector } from "../../../../hook/useTypedSelector";
import { useActions } from "../../../../hook/useActions";
import { useEffect, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import { Formik, FormikConfig, FormikProps, useFormik } from "formik";
import dayjs, { Dayjs } from 'dayjs';
import FormikInput, { StyledTextField } from "../../../../components/FormikInput";

interface Values {
    selectedEnterprise: number | undefined;
    averageWorkersCount: number | undefined;
    laborPaymentFund: number | undefined;
    dateStart: Dayjs | undefined;
    dateEnd: Dayjs | undefined;
}
const CreateIncomeData = () => {
    const { t } = useTranslation();
    const { codes } = useTypedSelector<any>(state => state.incomeData);
    const { getCodesRequest, getEnterpricesRequest, createIncomeDataRequest, createIncomeDataDefault } = useActions();
    const { enterprices } = useTypedSelector<any>(state => state.enterprices);
    const { createIncomeData } = useTypedSelector<any>(state => state.incomeData);
    const { loading, success, error } = createIncomeData;

    useEffect(() => {
        if(success){
            createIncomeDataDefault();
            navigate("/panel/incomeData");
        }
    }, [success]);
    const navigate = useNavigate();

    useEffect(() => {
        getCodesRequest();
        if (enterprices.length == 0) {
            getEnterpricesRequest();
        }
    }, [])
    const formik = useRef<any>();
    //console.log("rows", rows);
    
    const validationSchema = yup.object({
        selectedEnterprise: yup
            .number()
            .required(t("incomeData:required") as string),
        averageWorkersCount: yup
            .number()
            .required(t("incomeData:required") as string),
        laborPaymentFund: yup
            .number()
            .required(t("incomeData:required") as string),
        dateEnd: yup
            .date()
            .required(t("incomeData:required") as string),
        dateStart: yup
            .date()
            .required(t("incomeData:required") as string),
    });
    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card elevation={0} sx={{ backgroundColor: "#FAF8F8", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingY: 1 }}>
                            <Button onClick={() => { navigate("/panel/incomeData") }} color="secondary" startIcon={<ArrowBackIcon />}>
                                {t("incomeData:allTitle")}
                            </Button>
                        </Card>
                    </Grid>                    
                    <Formik
                        initialValues={{
                            selectedEnterprise: undefined,
                            averageWorkersCount: undefined,
                            laborPaymentFund: undefined,
                            dateStart: undefined,
                            dateEnd: undefined,
                        }}
                        innerRef={formik}
                        validationSchema={validationSchema}
                        onSubmit={(values: Values) => {
                            console.log();
                            let rows: any = [];
                            codes.map((el: any) => {
                                let iS = (document?.getElementById(el.id + ":start") as HTMLInputElement)?.value;
                                let iE = (document?.getElementById(el.id + ":end") as HTMLInputElement)?.value;
                                if (iS != "" || iE != "") {
                                    rows.push({
                                        rowCodeId: el.id,
                                        onStartPeriod: iS == "" ? null : parseFloat(iS),
                                        onEndPeriod: iE == "" ? null : parseFloat(iE)
                                    });
                                }
                            });
                            let model = {
                                enterpriseId: values.selectedEnterprise!,
                                averageWorkersCount: values.averageWorkersCount!,
                                laborPaymentFund: values.laborPaymentFund!,
                                startPeriodDate: values.dateStart!?.toDate().toJSON(),
                                endPeriodDate: values.dateEnd!?.toDate().toJSON(),
                                rows: rows
                            };
                            //console.log(model)
                            createIncomeDataRequest(model);
                        }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleSubmit
                            /* and other goodies */
                        }) => (
                            <>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12} md={4} style={{ paddingTop: "8px" }}>
                                    <TextField
                                        select
                                        sx={{ width: "100%" }}
                                        label={t("incomeData:selectEnterprise")}
                                        id="selectedEnterprise"
                                        name="selectedEnterprise"
                                        value={values["selectedEnterprise"]}
                                        onChange={handleChange}
                                        error={touched["selectedEnterprise"] && Boolean(errors["selectedEnterprise"])}
                                        helperText={touched["selectedEnterprise"] && errors["selectedEnterprise"]}
                                    //value={selectedEnterprise}
                                    //onChange={(el) => setSelectedEnterprise(+el.target.value)}
                                    >
                                        {enterprices.map((el: any) =>
                                            <MenuItem key={el.id} value={el.id}>
                                                {el.name}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ paddingTop: "0px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                sx={{ width: "100%", paddingTop: "0px" }}
                                                value={values.dateStart}
                                                onChange={(e) => { setFieldValue("dateStart", e) }}
                                                //error={touched.dateStart && Boolean(errors.dateStart)}
                                                slotProps={{
                                                    textField: {
                                                        helperText: touched.dateStart && errors.dateStart,
                                                    },
                                                }}
                                                //onChange={(newValue: any) => setDateStart(newValue)}
                                                //value={dateStart}
                                                label={t("incomeData:selectDateStart")} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ paddingTop: "0px" }}>
                                    <LocalizationProvider style={{ width: "100%" }} dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                sx={{ width: "100%", paddingTop: "0px" }}
                                                value={values.dateEnd}
                                                onChange={(e) => { setFieldValue("dateEnd", e) }}
                                                //error={touched.dateEnd && Boolean(errors.dateEnd)}
                                                slotProps={{
                                                    textField: {
                                                        helperText: touched.dateEnd && errors.dateEnd,
                                                    },
                                                }}
                                                //value={dateEnd}
                                                //onChange={(newValue: any) => setDateEnd(newValue)} 
                                                label={t("incomeData:selectDateEnd")}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ paddingTop: "0px" }}>
                                    <StyledTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        id={"averageWorkersCount"}
                                        label={t("incomeData:averageWorkersCount")}
                                        name={"averageWorkersCount"}
                                        autoComplete={"averageWorkersCount"}
                                        variant="outlined"                                        
                                        value={values["averageWorkersCount"]}
                                        onChange={handleChange}
                                        error={touched["averageWorkersCount"] && Boolean(errors["averageWorkersCount"])}
                                        helperText={touched["averageWorkersCount"] && errors["averageWorkersCount"]}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} style={{ paddingTop: "0px" }}>
                                    <StyledTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        id={"laborPaymentFund"}
                                        label={t("incomeData:laborPaymentFund")}
                                        name={"laborPaymentFund"}
                                        autoComplete={"laborPaymentFund"}
                                        variant="outlined"                                        
                                        value={values["laborPaymentFund"]}
                                        onChange={handleChange}
                                        error={touched["laborPaymentFund"] && Boolean(errors["laborPaymentFund"])}
                                        helperText={touched["laborPaymentFund"] && errors["laborPaymentFund"]}
                                    />
                                </Grid>
                            </>
                        )}
                    </Formik>
                    <Grid item xs={12} md={4}>
                        {error != null ? <FormHelperText error>{t("register:registerError")}</FormHelperText> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>{t("incomeData:rowCode")}</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>{t("incomeData:onStartPeriod")}</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>{t("incomeData:onEndPeriod")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {codes.map((el: any) =>
                                        <>
                                            <RowItem el={el} />
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button variant="contained"
                            onClick={() => formik.current != undefined ? formik.current.handleSubmit() : null}>
                            {t("incomeData:save")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CreateIncomeData;




interface IRowItem {
    el: any,
}
const RowItem = (props: IRowItem) => {
    const { el } = props;
    return (
        <>
            {el.rowCode == 1000 ?
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                        І. Необоротні активи
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                : null}
            {el.rowCode == 1100 ?
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                        II. Оборотні активи
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                : null}
            {el.rowCode == 1400 ?
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                        I. Власний капітал
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                : null}
            {el.rowCode == 1600 ?
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} component="th" scope="row">
                        III. Поточні зобов'язання
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                : null}
            <TableRow
                key={el.rowCode}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {el.title}
                </TableCell>
                <TableCell>{el.rowCode}</TableCell>
                <TableCell>
                    <TextField id={el.id + ":start"}
                        type="number" variant="outlined" />
                </TableCell>
                <TableCell>
                    <TextField id={el.id + ":end"}
                        type="number" variant="outlined" />
                </TableCell>
            </TableRow>
        </>
    )
}