import { Link } from "@mui/material";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

// import MenuIcon from '@mui/icons-material/Menu';

const DefaultHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  }
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 340;
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <LinkRouter to="" >
          <img src={require("../../../assets/img/logo_kairos_finance.png")} />
        </LinkRouter>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={()=>{navigate("")}}>
            <ListItemText primary={t("navbar:home")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}  onClick={()=>{navigate("login")}}>
            <ListItemText primary={t("navbar:login")} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <header>
      <AppBar elevation={0} position="static" sx={{borderBottom:"#D3D3D3 1px solid", backgroundColor: "#fff", zIndex:10 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display:"flex", alignItems: "center", flexDirection:"row" }}>
            <LinkRouter to="" >
              <img width={"170px"} src={require("../../../assets/img/logo_kairos_finance.png")} />
            </LinkRouter>
            <div style={{marginLeft:"10px", flexDirection:"row", display:"flex"}}>
              <Button onClick={() => { handleChangeLang("uk") }} sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "18px" }}>
                UA
              </Button>
              <Typography fontSize={18} lineHeight={"40px"} color="primary">|</Typography>
              <Button onClick={() => { handleChangeLang("en") }} sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "18px" }}>
                EN
              </Button>

            </div>
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <LinkRouter to="" style={{ marginLeft: "15px" }}>
              <Button sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "17px" }}>
                {t("navbar:home")}
              </Button>
            </LinkRouter>
            <LinkRouter to="description" style={{ marginLeft: "15px" }}>
              <Button sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "17px" }}>
                {t("navbar:description")}
              </Button>
            </LinkRouter>
            <LinkRouter to="documentation" style={{ marginLeft: "15px" }}>
              <Button sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "17px" }}>
                {t("navbar:documentation")}
              </Button>
            </LinkRouter>
            <LinkRouter to="tarifs" style={{ marginLeft: "15px" }}>
              <Button sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "17px" }}>
                {t("navbar:tarifs")}
              </Button>
            </LinkRouter>
            <LinkRouter to="login" style={{ marginLeft: "15px", marginRight: "35px" }}>
              <Button sx={{ textTransform: "capitalize", textDecoration: "none", fontWeight: 400, display: "inline-block", fontSize: "17px" }}>
                {t("navbar:login")}
              </Button>
            </LinkRouter>


          </Box>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>


        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </header>
  );
};

export default DefaultHeader;