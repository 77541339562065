
export enum GetLiquidityAnalysisTypes {
  GET = "GET_LIQUIDITY_ANALYSIS",
  GET_SUCCESS = "GET_LIQUIDITY_ANALYSIS_SUCCESS",
  GET_ERROR = "GET_LIQUIDITY_ANALYSIS_ERROR",
}

export interface ILiquidityAnalysisState {
  data: {};
  getData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
}
export interface IGetLiquidityAnalysisResult {
  data: [];
  success: boolean;
  error: string;
}
export interface IGetEditResult {
  data: {};
  success: boolean;
  error: string;
}
export interface IResult {
  success: boolean;
  error: string;
}

export interface GetLiquidityAnalysisRequest {
  type: GetLiquidityAnalysisTypes.GET;
}
export interface GetLiquidityAnalysisSucces {
  type: GetLiquidityAnalysisTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetLiquidityAnalysisError {
  type: GetLiquidityAnalysisTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export type LiquidityAnalysisActions =
  | GetLiquidityAnalysisRequest
  | GetLiquidityAnalysisSucces
  | GetLiquidityAnalysisError;
