import { takeLatest, put, call, select } from "redux-saga/effects";
import { apiRequest } from "../../apiRequest";
import { URLs } from "../../urls";
import {
  IGetGraphAnalysisFVFMResult,
  IResult,
  GetGraphAnalysisFVFMTypes,
  GetGraphAnalysisFVFMSucces,
  GetGraphAnalysisFVFMError,
  IGetEditResult,
} from "./types";

function* getGraphAnalysisFVFMRequest(action: any) {
  try {
    // API call
    const request: IGetEditResult = yield call(apiRequest, {
      endpoint: URLs.GET_GRAPH_ANALYSIS_FV_TO_FM+action.id,
      method: "GET",
    });
    if (request.success) {
      let putData: GetGraphAnalysisFVFMSucces = {
        type: GetGraphAnalysisFVFMTypes.GET_SUCCESS,
        data: request.data.items,
        loading: false,
      };
      yield put<GetGraphAnalysisFVFMSucces>(putData);
    } else {
      let putData: GetGraphAnalysisFVFMError = {
        type: GetGraphAnalysisFVFMTypes.GET_ERROR,
        error: request.error,
        loading: false,
      };
      yield put<GetGraphAnalysisFVFMError>(putData);
    }
  } catch (e: any) {
    let putData: GetGraphAnalysisFVFMError = {
      type: GetGraphAnalysisFVFMTypes.GET_ERROR,
      error: e,
      loading: false,
    };
    yield put<GetGraphAnalysisFVFMError>(putData);
  }
}

export function* handler() {
  yield takeLatest(GetGraphAnalysisFVFMTypes.GET, getGraphAnalysisFVFMRequest);
}
