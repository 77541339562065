import { Button, Card, CardContent, Grid, Typography, IconButton, Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useActions } from "../../../hook/useActions";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const IncomeData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getIncomeDataRequest, deleteIncomeDataRequest } = useActions();
    const { incomeDatas, deleteIncomeData } = useTypedSelector<any>(state => state.incomeData);
    const {success}= deleteIncomeData;

    const [open, setOpen] = useState(false);
    const [deletingId, setDeletingId] = useState(0);
    useEffect(() => {
        getIncomeDataRequest();
    }, [,success])
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card elevation={0} sx={{ backgroundColor: "#FAF8F8", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingY: 1 }}>
                        <Button onClick={() => { navigate("create") }} color="secondary" startIcon={<AddCircleOutlineIcon />}>
                            {t("incomeData:addTitle")}
                        </Button>
                    </Card>
                </Grid>
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...style, width: 400 }}>
                        <h2 id="parent-modal-title">{t("enterprices:areYouSureTitle")!}</h2>
                        <div style={{flexDirection:"row"}}>
                            <Button onClick={()=>{deleteIncomeDataRequest(deletingId); setOpen(false)}} sx={{ marginX: 2 }} title={t("enterprices:deleteTitle")!} color="error">{t("enterprices:deleteTitle")!}</Button>
                            <Button onClick={()=>setOpen(false)} sx={{ marginX: 2 }} title={t("enterprices:calcelTitle")!}>{t("enterprices:calcelTitle")!}</Button>
                        </div>
                    </Box>
                </Modal>
                <Grid item xs={12}>
                </Grid>
                {incomeDatas.map((el: any) => (
                    <Grid item xs={12} md={4}>
                        <Card elevation={0} sx={{ paddingX: 0.7, backgroundColor: "#FAF8F8" }}>
                            <CardContent>
                                <Box display={"flex"} justifyContent={"flex-end"}>
                                    <IconButton onClick={() => { 
                                        navigate("edit/"+el.id) 
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => { setDeletingId(el.id); setOpen(true) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                                <Typography variant={"subtitle1"} sx={{ fontWeight: "600" }}>Підприємство:</Typography>

                                <Typography variant={"h6"} color="primary" sx={{ fontWeight: "600" }}>{el.enterpriseTitle}</Typography>
                                <Typography variant={"subtitle1"} >{t("incomeData:startPeriod")}: {moment(el.startPeriodDate).format("DD.MM.yyyy")}</Typography>
                                <Typography variant={"subtitle1"}>{t("incomeData:endPeriod")}: {moment(el.endPeriodDate).format("DD.MM.yyyy")}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default IncomeData;