import { useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import { APP_ENV } from "../../../../env";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../../../hook/useActions";
import { IFindLoginPost } from "../../../../store/reducers/user/types";
import { useTypedSelector } from "../../../../hook/useTypedSelector";

const GoogleAuth = () => {
  const navigate = useNavigate();

  const { findLoginRequest, loginDefault } = useActions();
  const { findLogin } = useTypedSelector(state => state.user);
  const { token } = findLogin as IFindLoginPost; 
  console.log("Find login: ", findLogin);
  const handleGoogleLogin = (resp: any) => {

    console.log("Google login resp", resp);
    console.log("Token Id", resp.credential);
    const model : IFindLoginPost = {
      provider: "Google",
      token: resp.credential
    }; 
    findLoginRequest(model);
  };

  useEffect(() => {
    //console.log("APP_ENV", process.env.REACT_APP_GOOGLE_CLIENT_ID);
    //global google
    window.google.accounts!.id.initialize({
      client_id:  APP_ENV.GOOGLE_CLIENT_ID,
      callback: handleGoogleLogin,
    });

    // Display the One Tap prompt
    // window.google.accounts.id.prompt();

    window.google.accounts!.id.renderButton(
      document.getElementById("customBtn"),
      {
        theme: "outline",
        size: "large",
        type: "icon"//"standart"
      }
    );
  }, []);

  useEffect(() => {
    if(token!=null)
    {
      const tokenId = token;
      loginDefault();
      navigate("/confirm-google?token="+tokenId);
    }
  }, [token]);

  return (
    <>
      <div id="customBtn"></div>
    </>
  );
};
export default GoogleAuth;
