
export enum GetCoefUsingTypes {
  GET = "GET_COEF_USING",
  GET_SUCCESS = "GET_COEF_USING_SUCCESS",
  GET_ERROR = "GET_COEF_USING_ERROR",
}

export interface ICoefUsingState {
  data: [];
  getData: {
    loading: boolean;
    error: null | string;
    success: boolean;
  };
}
export interface IGetCoefUsingResult {
  data: [];
  success: boolean;
  error: string;
}
export interface IGetEditResult {
  data: {items:[]};
  success: boolean;
  error: string;
}
export interface IResult {
  success: boolean;
  error: string;
}

export interface GetCoefUsingRequest {
  type: GetCoefUsingTypes.GET;
}
export interface GetCoefUsingSucces {
  type: GetCoefUsingTypes.GET_SUCCESS;
  data: {};
  loading: boolean;
}

export interface GetCoefUsingError {
  type: GetCoefUsingTypes.GET_ERROR;
  error: string;
  loading: boolean;
}

export type CoefUsingActions =
  | GetCoefUsingRequest
  | GetCoefUsingSucces
  | GetCoefUsingError;
